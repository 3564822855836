import React from 'react'

export default function Message() {
    return (
        <div style={{ background: "#f3f4f4" }}>
            <div className='message_head_box'>
                Private Conversation between @randomchat and Brand Name
            </div>

            <div className='message-box'>
                {/* <div style={{ background: "#d24e62", height: 10, }}></div> */}
                <div className='row'>
                    <div className='col-3'>
                        <div className='one-box'>
                            <div style={{ marginLeft: 20, position: "relative", top: 30 }}>History</div>
                            <div style={{ marginLeft: 20, marginTop: 60 }}>Private Message History</div>
                            <li style={{ marginLeft: 40 }}>Private Reply</li>
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className='mid-box'>
                            <div className='mid-link'>Mark as Complete</div>
                            <div className='mid-sm-box1'>Lorem ipsum dolor sit amet consectetur. Sed lectus vitae varius diam neque ultricies mattis. Cras sapien non varius tempor. Posuere aenean et velit arcu nisl fermentum malesuada tristique velit. Arcu morbi et nunc vel mattis enim aliquet lorem feugiat. Eget in lectus vestibulum pharetra gravida feugiat pellentesque donec eu. Lorem interdum quam consectetur congue dolor vitae vel augue pellentesque.
                                <div>Pulvinar consequat nunc purus commodo. Id eget mattis sed id cursus leo.</div>
                            </div>

                            <div className='mid-sm-box2'>
                                <textarea className='message_area' placeholder='Enter Text'>

                                </textarea>

                                <div className='row message_options'>
                                    <div className='col-1'>
                                        <img src={require('../Assets/bookmark.png')} className='circle-box1' />
                                    </div>
                                    <div className='col-1'>
                                        <img src={require('../Assets/mood.png')} className='circle-box1' />
                                    </div>
                                    <div className='col-1'>
                                        <img src={require('../Assets/icon2-icon.png')} className='circle-box1' />
                                    </div>
                                    <div className='col-1'>
                                        <img src={require('../Assets/camera-icon.png')} className='circle-box1' />
                                    </div>
                                    <div className='col-8'>
                                    </div>
                                </div>

                                <div className='d-flex align-items-center justify-content-between mt-2'>
                                    <div className='d-flex align-items-center my-2'>
                                        <input type='checkbox' />
                                        <label className='mx-2'>
                                            Mark all complete
                                        </label>
                                    </div>

                                    <div>
                                        <button className='message_send_btn'>
                                            Send
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-3'>
                        <div className='one-box'>
                            <div className='box-div1'>
                                <img src={require('../Assets/circle-inbox.png')} className='circle-box' />
                            </div>

                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <div><img src={require('../Assets/Social media icons.png')} className='circle-box1' /></div>
                                <div style={{ marginTop: 58, marginLeft: 10, fontWeight: "500" }}>Private</div>
                            </div>

                            <div style={{ color: "#4468b1", marginTop: 10, textDecoration: "underline", fontSize: 14, marginLeft: 30 }}>randomm124</div>

                            <div style={{ color: "grey", marginLeft: 30, fontSize: 14, marginTop: 30 }}>0 Followers</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
