import React from 'react'

export default function Header() {
    return (
        <div className='header d-flex'>
            <div className='welcome_section d-flex'>
                <img src={require('../Assets/Avtar.png')} className='avtar' />
                <div className='welcome_msg'>
                    <h4>Welcome, Yogesh!</h4>
                </div>
            </div>


            <div className='d-flex'>
                <div className='trial_msg'>
                    7 days remaining in trial
                </div>
                <button className='feature_btn btn'>
                    <img src={require('../Assets/star.png')} />
                    Trial more features
                </button>

                <button className='subscribe_btn btn'>
                    Start my subscription
                </button>
            </div>
        </div>
    )
}
