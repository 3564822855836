import React, { useState } from 'react'
import HeadLogo from '../Assets/Icons/sign-logo.png'
import axios from 'axios';
import { baseURL } from './config';
import { message, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { Link, useNavigate } from 'react-router-dom';
import Steps from '../Steps';

export default function Signup() {

    const [Details, setDetails] = useState({
        firstname: '',
        lastname: '',
        email: '',
        password: ''
    })

    const navigate = useNavigate()

    const [Mobile, setMobile] = useState()

    const [isLoader, setIsLoader] = useState(false);

    const handel = (e) => {
        const newData = { ...Details };
        newData[e.target.name] = e.target.value;
        setDetails(newData);
    }

    // const Login = () => {
    //     axios.post(`${baseURL}/user/login`, {
    //         "email_id": Details.email,
    //         "password": Details.password
    //     })
    //         .then((res) => {
    //             setIsLoader(false);
    //             localStorage.setItem("Email", Details.email);
    //             localStorage.setItem("user_Token", res.data.data.token);
    //         })
    //         .catch((err) => {
    //             console.log(err)
    //         })
    // }

    const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />;

    const Register = () => {
        setIsLoader(true);
        const emailRegex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;

        if (Details.firstname == '') {
            message.error("Enter First Name");
            setIsLoader(false);
        }
        else
            if (Details.lastname == '') {
                message.error("Enter Last");
                setIsLoader(false);
            }
            else if (Details.email == '' || emailRegex.test(Details.email) == false) {
                message.error('Enter Correct Email Address');
                setIsLoader(false);
            }
            else if (Mobile == '') {
                message.error("Enter Correct Mobile Number");
                setIsLoader(false);
            }
            else if (Details.password == '') {
                message.error("Enter Password");
                setIsLoader(false);
            }
            else {
                axios.post(`${baseURL}/user/register`, {
                    first_name: Details.firstname,
                    last_name: Details.lastname,
                    email_id: Details.email,
                    password: Details.password,
                    mobile: Mobile
                })
                    .then((res) => {
                        navigate("/Onboarding");
                    })
                    .catch((err) => {
                        message.error("Please Try Again Later");
                        setIsLoader(false);
                        console.log(err)
                    })
            }
    }
    return (
        <div className='row h-100 no-gutter mx-0'>
            <div className='col-lg-8'>
                <div className='sign-head d-flex align-items-center justify-content-between p-3'>
                    <div className='w-50'>
                        <img src={HeadLogo} className='signlogo' />
                    </div>

                    <div className='w-50 text-right'>
                        <label className='already_label'>
                            Already have an account?
                        </label>

                        <Link to="/" className='login_btn'>
                            Log in
                        </Link>
                    </div>
                </div>

                <div className='steps_main_container'>
                    <Steps steps={1}/>
                </div>

                <div className='sign_form_section'>

                    <div className='text1'>Start your free, 30 day trial</div>

                    <div className='text2'>No credit card required</div>

                    <div className='d-flex align-item-center justify-content-between'>
                        <div className='first-field'>
                            <div className='text0'>First Name</div>
                            <input type='text' placeholder='Enter First Name' name="firstname" onChange={(e) => { handel(e) }} className='field1'></input>
                        </div>

                        <div className='first-field'>
                            <div className='text0'>Last Name</div>
                            <input type='text' placeholder='Enter Last Name' name="lastname" onChange={(e) => { handel(e) }} className='field2'></input>
                        </div>
                    </div>


                    <div className='text'>Email Address</div>
                    <div className='field-div'>
                        <input type='text' placeholder='Enter Email' name="email" onChange={(e) => { handel(e) }} className='field'></input>
                    </div>

                    <div className='text'>Phone</div>
                    <div className='field-div'>
                        {/* <input type='text' placeholder='Enter Phone No.' name="mobile" onChange={(e) => { handel(e) }} className='field'></input> */}
                        <PhoneInput
                            placeholder="Enter Phone No."
                            defaultCountry="IN"
                            value={Mobile}
                            onChange={setMobile}
                            limitMaxLength= {true}
                            />
                    </div>

                    <div className='text'>Password</div>
                    <div className='field-div'>
                        <input type='password' name="password" onChange={(e) => { handel(e) }} className='field'></input>

                    </div>

                    <div className='btn-div'>
                        <button className='create-btn' onClick={() => { Register() }}>
                            {
                                isLoader
                                    ?
                                    <Spin indicator={antIcon} />
                                    :
                                    "Create Account"

                            }
                        </button>
                    </div>

                    <div className='long-text'>By clicking “Create Account”, I agree to Sprout Social’s <b>Terms of Service</b><br /> and acknowledge its <b>Privacy Policy</b>.</div>
                </div>

            </div>

            <div className='col-lg-4 bg-image'>
                <div className='text-center'>
                    <p className='review-label'>
                        “With Oolook I can build social content out as far as I want into the future but also tailor campaigns if we see certain trends within the industry.”
                    </p>

                    <img src={require('../Assets/Review_img.png')} style={{ width: '20%' }} />

                    <div className='d-flex flex-column'>
                        <span className='review_name'>
                            Justin Moore
                        </span>

                        <span>
                            Marketing Manager
                        </span>

                        <span>
                            Trident Properties PVT. LTD.

                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}
