import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useSelector } from 'react-redux'
import { baseURL } from './config';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
// import faker from 'faker';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

export function Impressionchart() {
    const [InstaImpression, setInstaImpression] = useState([]);
    const [isLoader, setIsloader] = useState(true);
    const [label, setLabel] = useState([]);
    const [FbImpressrions, setFbImpressions] = useState([]);

    const [AudienceGrowths, setAudienceGrowths] = useState(null);
    const Sinceselector = useSelector((state) => {
        return state.SinceDates
    });

    const untilSelector = useSelector((state) => {
        return state.UntilDates
    })

    const ImpressionChart = async (MediaId, accessToken) => {
        InstaImpression.length = 0
        label.length = 0
        const since = new Date(Sinceselector[0] * 1000)
        const until = new Date(untilSelector[0] * 1000);

        await axios.post(`${baseURL}/instagram/impressions`, {
            mediaId: "17841433401221135",
            accessToken: "EAACyZBrKYZC58BO6taabgLzA89w0fNAe57ZCXVsOSmzH4cWAJhe5VLS8pRvZBMR6yZAkArNmUNEsUggRUt5mvC0FnWrojmZBo30UX2ASsz62JNkmt775FiH5gzDQHef5WbZBuPvFYsZAZBtaLjWqpEqngbKZC8a8YcKS1CCcJAyyOWhwT5UG8VNAkJdfFsh8EwXuDql1cSqVMn",
            since: since.getFullYear() + "-" + (since.getMonth() + 1).toString().padStart(2, "0") + "-" + since.getDate().toString().padStart(2, "0"),
            until: until.getFullYear() + "-" + (until.getMonth() + 1).toString().padStart(2, "0") + "-" + until.getDate().toString().padStart(2, "0")
        })
            .then((res) => {
                (res.data).map(async (elem) => {
                    if((elem.data.data).length!=0)
                    {
                        InstaImpression.push(
                            {
                                x: (elem.date).split("-")[1] + "/" + (elem.date).split("-")[2],
                                y: elem.data.data[0].total_value.value
                            }
                        )
                    }
                })

                setTimeout(() => {
                    setIsloader(true);
                }, 5000);
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const FbImpression = async (tokenID, accessToken) => {
        FbImpressrions.length = 0
        setIsloader(false)
        await axios.post(`${baseURL}/facebook/insights`, {
            mediaId: tokenID,
            accessToken: accessToken,
            since: Sinceselector[0],
            until: untilSelector[0]
        })
            .then((res) => {
                const currentMonth = (new Date(Sinceselector[0] * 1000).getMonth()) + 1
                const currentDate = (new Date(Sinceselector[0] * 1000).getDate())
                FbImpressrions.push({
                    x: ("0" + currentMonth).slice(-2) + "/" + ("0" + currentDate).slice(-2),
                    y: 0
                })

                for (var i = 0; i < (res.data.data[0].values).length; i++) {
                    FbImpressrions.push(
                        {
                            x: (res.data.data[0].values[i].end_time).split("T")[0].split("-")[1] + "/" + (res.data.data[0].values[i].end_time).split("T")[0].split("-")[2],
                            y: res.data.data[0].values[i].value
                        }
                    )
                }

                ImpressionChart(tokenID, accessToken)

            })
            .catch((err) => {
                console.log(err)
            })
    }

    const GetProfile = async () => {
        await axios.get(`${baseURL}/token/get/${localStorage.getItem("Email")}`)
            .then((res) => {
                // setFBAccessToken(res.data.Tokens.fb_access_token)
                // setMediaId(res.data.Tokens.fb_id)
                FbImpression(res.data.Tokens.fb_id, res.data.Tokens.fb_access_token)

            })
            .catch((err) => {
                console.log(err)
            })
    }

    const options = {
        responsive: true,
        maintainAspectRatio: false,

        scales: {
            x: {
                grid: {
                    display: false,
                }
            }
        },
        elements: {
            point: {
                radius: 0
            }
        },
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                labels: {
                    usePointStyle: true,
                    pointStyle: 'rectRounded',
                    padding: 30
                }
            }
        }
    };

    const labels = label;

    const data = {
        labels,
        datasets: [
            {
                label: 'Instagram', // Name the series
                data: InstaImpression, // Specify the data values array
                fill: true,
                borderColor: '#CE3665', // Add custom color border (Line)
                backgroundColor: '#CE3665', // Add custom color background (Points and Fill)
                borderWidth: 0.4, // Specify bar border width
                tension: 0.3

            },
            {
                label: 'Facebook', // Name the series
                data: FbImpressrions, // Specify the data values array
                fill: true,
                borderColor: '#6F5ED3', // Add custom color border (Line)
                backgroundColor: '#6F5ED3', // Add custom color background (Points and Fill)
                borderWidth: 0.4, // Specify bar border width
                tension: 0.3

            }
        ],
    };

    useEffect(() => {
        GetProfile()
    }, [Sinceselector, untilSelector])

    if (isLoader == false) {
        return (
            <div id='overlay'>
                <img src={require('../Assets/logo_overlay.png')} />
            </div>
        )
    }

    return (
        <>
            <div className='chart_section'>
                <div className='chart_heading d-flex flex-column px-3 py-3'>
                    <label className='label_head'>
                        Impressions
                    </label>

                    <label className='label_sub_head'>
                        Review how your content was seen across networks during the reporting period.
                    </label>
                </div>

                <div style={{ height: '40vh', paddingLeft: '30px', paddingRight: '30px', paddingTop: '40px' }}>
                    <Line options={options} data={data} />
                </div>

                <div className='w-100 px-3'>
                    <table className='w-100 table chart_table'>
                        <tr>
                            <th>
                                Impression Metrics
                            </th>

                            <th>
                                Totals
                            </th>

                            <th>
                                % Change
                            </th>
                        </tr>

                        <tbody>
                            <tr>
                                <td>
                                    Total Impressions
                                </td>

                                <td>
                                    {
                                        InstaImpression.length != 0
                                            ?
                                            data.datasets[0].data.reduce((accumulator, currentValue) =>
                                                accumulator + currentValue.y, 0
                                            ) +
                                            data.datasets[1].data.reduce((accumulator, currentValue) =>
                                                accumulator + currentValue.y, 0
                                            )
                                            :
                                            null
                                    }
                                </td>

                                <td>
                                    2.1%
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    Total Net Audience Growth
                                </td>

                                <td>
                                    {/* 23 */}
                                </td>

                                <td>
                                    130%
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    Instagram Impressions
                                </td>
                                <td>
                                    {
                                        InstaImpression.length != 0
                                            ?
                                            data.datasets[0].data.reduce((accumulator, currentValue) =>
                                                accumulator + currentValue.y, 0
                                            )
                                            :
                                            null
                                    }
                                </td>

                                <td>
                                    2400%
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    Facebook Impressions
                                </td>
                                <td>
                                    {
                                        FbImpressrions.length != 0
                                            ?
                                            data.datasets[1].data.reduce((accumulator, currentValue) =>
                                                accumulator + currentValue.y, 0
                                            )
                                            :
                                            null
                                    }
                                </td>

                                <td>
                                    2400%
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}
