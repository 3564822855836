import React from 'react'
import GrpImg from '../Assets/grp_img.png'
import ProfileImg from '../Assets/grp_profile.png'

export default function GroupsProfile() {
  return (
    <div>
        <div className='grp-head'>Groups & Social Profiles</div>

        <div className='grp-box1'>
            <div className='grp-box1-text1'>Your Company</div>
            <div className='grp-box1-text2'>Add, remove and manage your groups and social profiles</div>
            <hr/>
            <div className='grp-box1-text1'>Your Company</div>
            <div>
                <img src={GrpImg} className='grp-img' />
            </div>

            <div className='member_count'>2 Team members in <span style={{color:'#4D94CF'}}>Group Name</span></div>

            <div>
                <img src={ProfileImg} className='grp-social' />
            </div>

            <div className='grp-box1-text1'>1 Profile Connected</div>

            <hr/>

            <div className='profile-text'>All Available Profiles Connected</div>
            <div className='profile-text'>
                <button className='profile-btn'>Connect a Profile</button>
            </div>
        </div>
        
    </div>
  )
}
