import React, { useState, useEffect } from 'react'
import { FileUploader } from "react-drag-drop-files";
import FontPicker from '@aw-web-design/font-picker-react';
import axios from 'axios';
import { baseURL } from '../config';

export default function AssetsUpload() {

    const fileTypes = ["JPG", "PNG", "GIF"];

    const [showType, setShowType] = useState('file');
    const [ViewType, setViewType] = useState('grid');
    const [fonts, setFonts] = useState();
    const [userDetail, setUserDetails] = useState([]);
    const [isLoader, setIsLoader] = useState(true);

    const GetonBoardingDetails = async () => {
        setIsLoader(false)
        let useremail;
        if (localStorage.getItem("Email")) {
            useremail = localStorage.getItem("Email");
        }
        else {
            useremail = sessionStorage.getItem("Email")
        }

        await axios.get(`${baseURL}/onboarding3/get-onboarding3/${useremail}`)
            .then((res) => {
                setUserDetails(res.data.OnBoarding_3)
                setIsLoader(true)
            })
            .catch((err) => {
                setIsLoader(true)
                console.log(err)
            })
    }

    useEffect(() => {
        GetonBoardingDetails()
    }, [])

    const dragNdrop = () => {
        return (
            <div className='d-flex flex-column align-items-center justify-content-center drag_box px-5 py-3'>
                <img src={require('../../Assets/folder_open.png')} className='folder_img' />
                <div className='mt-3'>
                    <label className='drag_head_name'>
                        Drag and Drop your files here
                    </label>
                </div>

                <label className='supported_file_text'>
                    Files supported PNG, JPG..
                </label>

                <div>
                    <button className='browse_btn p-2'>
                        <i className="fa fa-plus mx-1" aria-hidden="true"></i>
                        Browse
                    </button>
                </div>
            </div>
        )
    }

    // if (isLoader == false) {
    //     return (

    //     )
    // }

    return (
        <div>
            {
                isLoader == false
                &&
                <div id='overlay'>
                    <img src={require('../../Assets/logo_overlay.png')} />
                </div>
            }


            <div className='d-flex align-items-center justify-content-between my-2 mx-3'>
                <div>
                    <label className='section_heading'>
                        My Assets Libary
                    </label>
                </div>

                <div className='d-flex align-items-center'>
                    <div>
                        <div className='d-flex assets_toggle'>
                            <button className='assets_toogle_btn' onClick={() => { setShowType('file') }}>
                                <i className="fa fa-file-text mx-1" aria-hidden="true"></i>
                                Files
                            </button>

                            <button className='assets_toogle_btn' onClick={() => { setShowType('folder') }}>
                                <i className="far fa-folder mx-1"></i>
                                Folders
                            </button>
                        </div>
                    </div>


                    <div className='mx-2'>
                        <div className='d-flex assets_toggle'>
                            <button className='view_toogle_btn' onClick={() => { setViewType('list') }}>
                                <i className={ViewType == "list" ? "fa-solid fa-bars active_view" : "fa-solid fa-bars"}></i>
                            </button>

                            <button className='view_toogle_btn' onClick={() => { setViewType('grid') }}>
                                {/* <img className={ViewType == "grid" ? "active_view" : ""} src={require('../../Assets/grid_view.png')} style={{width:'20px'}}/> */}
                                {/* <i class="fa-solid fa-grid"></i> */}
                                <i className={ViewType == "grid" ? "fas fa-grip-horizontal active_view" : "fas fa-grip-horizontal"} aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>

                </div>

            </div>

            <div className='d-flex justify-content-between mt-3'>
                <div style={{ width: '32%' }}>
                    <FileUploader children={dragNdrop()} name="file" types={fileTypes} />
                </div>


                <div className='color_font_section'>
                    <div className='font_style'>
                        <div style={{ display: 'none' }}>
                            {
                                userDetail.length != 0
                                    ?
                                    <FontPicker
                                        apiKey="AIzaSyAub6MjUwcD5fmBd8zZpCzLbHoDfnsMJW8"
                                        activeFontFamily={userDetail[0].brand_font}
                                    />
                                    :
                                    null
                            }
                        </div>

                        {
                            userDetail.length != 0
                                ?
                                <p className='apply-font userFonts'>
                                    {
                                        userDetail[0].brand_font
                                    }
                                </p>
                                :
                                null
                        }
                    </div>

                    <div className='edit_font_head'>
                        <i class="fa fa-pencil mx-2" aria-hidden="true"></i>
                        Edit Color Palette & Fonts
                    </div>
                </div>

                <div className='logo_section'>
                    <label className='brand_head'>
                        Brand Name
                    </label>

                    {
                        userDetail.length != 0
                            ?
                            <img src={`https://oolook.azurewebsites.net/uploads/${userDetail[0].brand_photos}`} className='brand_img' />
                            :
                            <img src={require('../../Assets/test_brand.png')} className='brand_img' />
                    }

                    <div className='edit_font_head'>
                        <i class="fa fa-pencil mx-2" aria-hidden="true"></i>
                        Edit Logo & Company Info
                    </div>
                </div>
            </div>
        </div>
    )
}
