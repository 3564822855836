import React, { useEffect, useState } from 'react'
import Logo from '../Assets/Logo2.png'
import Dash from '../Assets/Dash_icon.png'
import inbox from '../Assets/inbox.png'
import publishing from '../Assets/publishing.png'
import reports from '../Assets/reports.png'
import Assets from '../Assets/Cloud.png'
import Profile from '../Assets/Add New.png'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { StoreSideBar } from './store/Slices/SIdeSlice'
import { sidebarstore } from './action'
import { baseURL } from './config'
import axios from 'axios'

export default function Sidebar() {
  const selector = useSelector((state) => {
    return state.SideBarName[0]
  });
  const [active, setActive] = useState('');
  const location = useLocation();
  const dispatch = useDispatch();

  const [showsubmenu, setShowsubmenu] = useState(false);

  const navigate = useNavigate();

  function Log_out() {
    // const token=localStorage.getItem('user_Token');

    // let config = {
    //   method: 'post',
    //   maxBodyLength: Infinity,
    //   url: `${baseURL}/user/logout`,
    //   headers: { 
    //     'Authorization': `Bearer ${token}`
    //   }
    // };

    // axios.request(config)
    // .then((res)=>{
    //   console.log(res)
    localStorage.removeItem("Email");
    localStorage.removeItem('user_Token');
    window.location.reload('/')
    // })
    // .catch((err)=>{
    //   console.log(config)
    //   console.log(err)
    // })
  }

  const LogOut = () => {
    if (localStorage.getItem("user_Token")) {
      localStorage.removeItem("user_Token")
      window.location.reload()
    }
    else if (sessionStorage.getItem('user_Token')) {
      sessionStorage.removeItem('user_Token')
      window.location.reload()
    }
    else {
      // setPrivate(false)
      return false
    }

  }

  return (
    <>
      <div className='sidebar'>
        <div className="logodiv">
          <img className="sidelogo" src={Logo} />
        </div>

        <ul className='sidenav_list'>
          <Link to="/Dashboard" onClick={() => { dispatch(StoreSideBar('/')); setShowsubmenu(false) }} className={selector == '/' ? 'links active d-flex' : 'links d-flex'}>
            <img className="sideicon" src={Dash} />
          </Link>

          <Link onClick={() => { dispatch(StoreSideBar('Inbox')); setShowsubmenu(true) }} className={selector == 'Inbox' ? 'links active d-flex' : 'links d-flex'}>
            <img className="sideicon" src={inbox} />
          </Link>

          <Link onClick={() => { dispatch(StoreSideBar('Reports')); setShowsubmenu(true) }} className={selector == 'Reports' ? 'links active d-flex' : 'links d-flex'}>
            <img className="sideicon" src={reports} />
          </Link>


          <Link onClick={() => { dispatch(StoreSideBar('Publishing')); setShowsubmenu(true) }} className={selector == "Publishing" || selector == "Approval" || selector == "Rejected" ? 'links active d-flex' : 'links d-flex'}>
            <img className="sideicon" src={publishing} />
          </Link>

          <Link onClick={() => { dispatch(StoreSideBar('Assets')); setShowsubmenu(true) }} className={selector == 'Assets' ? 'links active d-flex' : 'links d-flex'}>
            <img className="sideicon" src={Assets} />
          </Link>

          <Link to="Profile" onClick={() => { dispatch(StoreSideBar('Profile')); setShowsubmenu(false) }} className={selector == 'Profile' ? 'links active d-flex' : 'links d-flex'}>
            <img className="sideicon" src={Profile} />
          </Link>
        </ul>

        {/* Balance section */}
        <div className='bottom-section'>
          {/* Account Setting */}
          <Link onClick={() => { dispatch(StoreSideBar('Account & Setting')); setShowsubmenu(true) }} className={selector == 'Profile' ? 'account_setting links active d-flex' : 'account_setting links d-flex'}>
            <img src={require('../Assets/Avtar.png')} className='avtar' />
          </Link>
        </div>

      </div>

      {
        showsubmenu
          ?
          <div className='submenu'>

            <div className='d-flex flex-column sub_menu_header'>
              <label className='user_name'>
                Yogesh
              </label>

              <label className='page_name'>
                {selector}
              </label>
            </div>

            {/* Message Sub menu */}
            <ul className={selector == "Inbox" ? 'sub_menu_show' : 'sub_menu'}>
              <Link to="Inbox" onClick={() => setShowsubmenu(false)}>
                <li className={selector == "Inbox" ? 'active' : ''}>All Message</li>
              </Link>

              <Link to="Message" onClick={() => setShowsubmenu(false)}>
                <li className={selector == "Message" ? 'active' : ''}>Completed</li>
              </Link>
            </ul>

            {/* Report sub menu */}
            <ul className={selector == "Reports" ? 'sub_menu_show' : 'sub_menu'}>
              <Link to="/Dashboard/Reports" onClick={() => { setShowsubmenu(false); dispatch(StoreSideBar('Reports')); }}>
                <li className={location.pathname == "/Reports" ? 'active' : ''}>Profile Reports</li>
              </Link>

              <Link to="Reports/Postreports" onClick={() => setShowsubmenu(false)}>
                <li className={location.pathname == "/Reports/Postreports" ? 'active' : ''}>Post Reports</li>
              </Link>

              <Link to="Reports/Competitor" onClick={() => setShowsubmenu(false)}>
                <li className={location.pathname == "/Reports/Competitor" ? 'active' : ''}>Competitor Reports</li>
              </Link>
            </ul>

            {/* Publish sub menu */}
            <ul className={selector == "Publishing" || selector == "Approval" || selector == "Rejected" ? 'sub_menu_show' : 'sub_menu'}>
              <Link to="Publishing" onClick={() => { dispatch(StoreSideBar('Publishing')); setShowsubmenu(false) }}>
                <li className={selector == "Publishing" ? 'active' : ''}>Calender</li>
              </Link>

              <Link to="Approval" onClick={() => { dispatch(StoreSideBar('Approval')); setShowsubmenu(false) }}>
                <li className={selector == "Approval" ? 'active' : ''}>Need Approval</li>
              </Link>

              <Link to="Rejected" onClick={() => { dispatch(StoreSideBar('Rejected')); setShowsubmenu(false) }}>
                <li className={selector == "Rejected" ? 'active' : ''}>Rejected</li>
              </Link>
            </ul>

            {/* Assets sub menu */}
            <ul className={selector == "Assets" ? 'sub_menu_show' : 'sub_menu'}>
              <Link to="Assets" onClick={() => { dispatch(StoreSideBar('Assets')); setShowsubmenu(false) }}>
                <li className={selector == "Assets" ? 'submenu_active' : ''}>
                  <i class="fa fa-plus mx-1" aria-hidden="true"></i>
                  New
                </li>
              </Link>

              <Link to="Approval" onClick={() => { dispatch(StoreSideBar('Approval')); setShowsubmenu(false) }}>
                <li className={selector == "Recents" ? 'submenu_active' : ''}>Recents</li>
              </Link>

              <Link to="Rejected" onClick={() => { dispatch(StoreSideBar('Rejected')); setShowsubmenu(false) }}>
                <li className={selector == "Starred" ? 'submenu_active' : ''}>Starred</li>
              </Link>

              <Link to="Rejected" onClick={() => { dispatch(StoreSideBar('Rejected')); setShowsubmenu(false) }}>
                <li className={selector == "Bin" ? 'submenu_active' : ''}>Bin</li>
              </Link>
            </ul>

            <ul className={selector == "Account & Setting" ? 'sub_menu_show' : 'sub_menu'}>
              <Link to="AccountSetting" onClick={() => { dispatch(StoreSideBar('Account & Setting')); setShowsubmenu(false) }}>
                <li className={selector == "Account & Billing" ? 'submenu_active' : ''}>
                  Account & Billing
                </li>
              </Link>

              <Link to="GroupsProfile" onClick={() => { dispatch(StoreSideBar('GroupsProfile')); setShowsubmenu(false) }}>
                <li className={selector == "Groups & Social Profiles" ? 'submenu_active' : ''}>Groups & Social Profiles</li>
              </Link>
            </ul>
          </div>
          :
          null
      }
    </>
  )
}
