import React, { useEffect, useState } from 'react'

export default function FacebookModal({Previewimages,Previewcaptions}) {
    const [open, setOpen] = useState(true);

    return (
        <div className='w-100 d-flex flex-column align-items-center social_preview_box my-3'>
            <button onClick={() => { setOpen(!open) }} className='my-3 toggle_btn d-flex align-items-center justify-content-between'>
                <div className='d-flex align-items-center'>
                    <i class="fa-brands fa-facebook fa-lg mx-2" style={{ color: '#1877F2' }}></i>
                    <label className='social_name'>
                        Facebook
                    </label>
                </div>

                <div>
                    <i className={open ? "fa fa-angle-up" : "fa fa-angle-down"}></i>
                </div>
            </button>

            {
                open
                    ?
                    <div className='facebook_preview'>
                        <div className='d-flex p-2'>
                            <div>
                                <img src={require('../../Assets/grp_profile.png')} />
                            </div>

                            <div className='d-flex flex-column mx-2'>
                                <label className='userid_preview'>
                                    My Web-Space Owl
                                </label>

                                <label className='date_preview'>
                                    5 Mar
                                </label>
                            </div>
                        </div>

                        <div className='mx-2 preview_captions'>
                            {Previewcaptions}
                        </div>

                        <div className='d-flex justify-content-center'>
                            <img src={Previewimages} className="previewImgs" />
                        </div>

                        <div className='d-flex justify-content-between likes_options'>
                            <div>
                                <i className="fa fa-thumbs-up mx-1"></i>
                                Like
                            </div>

                            <div className='d-flex align-items-center'>
                                <img src={require('../../Assets/fb_comment.png')} className='fb_comments' />
                                Comment
                            </div>

                            <div>
                                <i className="fa fa-share mx-1"></i>
                                Share
                            </div>
                        </div>
                    </div>
                    :
                    null
            }
        </div>
    )
}
