import React, { useState } from 'react'

export default function Dropdown({ FilterOption }) {
    const [showFilter, setShowFilter] = useState(false)
    
    return (
        <div style={{ width: '23%' }}>
            <button className='d-flex align-items-center justify-content-between select_filter' onClick={() => { setShowFilter(!showFilter) }}>
                <div className='d-flex flex-column mx-2'>
                    <div className='filter_head'>
                       {FilterOption[0]}
                    </div>

                    {/* Options */}
                    <div className='filter_selcted_option'>
                        {FilterOption[1][0]}
                    </div>
                </div>

                <div>
                    <i class={showFilter ? "fa fa-angle-up" : "fa fa-angle-down"}></i>
                </div>
            </button>

            {/* options list */}
            {
                showFilter
                    ?
                    <div className='option_list'>
                        <ul>
                            {
                                FilterOption[1].map((elem) => {
                                    return (
                                        <li>
                                            {elem}
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                    :
                    null
            }
        </div>
    )
}
