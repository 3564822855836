import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { baseURL } from './config';
import { message } from 'antd';
import EmojiPicker from 'emoji-picker-react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'
import { DatePicker } from 'antd';
import { FileUploader } from "react-drag-drop-files";
import FacebookModal from './Social Desings/FacebookModal';

import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import InstagarmModal from './Social Desings/InstagarmModal';



export default function PublishPage() {
    const [Paltform, setPlatform] = useState('Facebook');
    const [access_token, setAccessToken] = useState();
    const [yt_access_token, setYt_access] = useState('');
    const [messages, setMessage] = useState('');
    const [facebookID, setFacebookID] = useState('');
    const [Fileupload, setFileUpload] = useState();
    const [ytexpiry, setYTexpiry] = useState();
    const [emoji, setEmoji] = useState();
    const [IG_ID, setIGID] = useState();
    const uploadfile = useRef(null);
    const messageRef = useRef(null);
    const [datepickertoggle, setDatePickerToggle] = useState(false);
    const [PhotoPreivew, setPhotoPreview] = useState();
    const [PublishType, setPublishType] = useState('IMAGE');
    const [uploadMenu, setUploadMenu] = useState(false);
    const [uploadedImg, setUploadImg] = useState([]);

    const [scheduleDates, setScheduleDate] = useState('');

    // const [editor] = useLexicalComposerContext();

    // function Editor() {
    //     const initialConfig = {
    //       namespace: 'MyEditor',
    //       theme,
    //       onError,
    // }

    // Youtube option
    const [title, setTitle] = useState();
    const [tags, setTags] = useState();
    const [category, setCategory] = useState();
    const [privacyStatus, setprivacyStatus] = useState();
    const [scope, setScope] = useState();

    const fileTypes = ["JPG", "PNG", "GIF"];


    const MonthName = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

    const getTokens = () => {
        const email = localStorage.getItem("Email");
        axios.get(`${baseURL}/token/get/${email}`)
            .then((res) => {
                // if (res.data.Tokens.tw_access_token !== "") {
                //     setTWToken(true)
                // }

                // if (res.data.Tokens.yt_access_token !== "") {
                //     setGoogleToken(true)
                // }

                // if (res.data.Tokens.insta_access_token !== "") {
                //     setInstagramToken(true)
                // }

                if (res.data.Tokens.fb_access_token !== "") {
                    setAccessToken(res.data.Tokens.fb_access_token)
                    setFacebookID(res.data.Tokens.fb_id)
                    setIGID(res.data.Tokens.insta_id)
                }

                if (res.data.Tokens.yt_access_token != "") {
                    setYt_access(res.data.Tokens.yt_access_token)
                    setYTexpiry(res.data.Tokens.yt_expiry_date)
                    setScope(res.data.Tokens.yt_scope)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const PostFacebook = async () => {
        if (messages == "") {
            message.error("Please Enter Some Text for Post")
        }
        else {
            if (Paltform == "Facebook") {
                if (sessionStorage.getItem("uploadImage")) {
                    await axios.post(`${baseURL}/facebook/post-image`, {
                        "pageId": facebookID,
                        "accessToken": access_token,
                        "message": messages,
                        "imageUrl": sessionStorage.getItem("uploadImage")
                    })
                        .then((res) => {
                            console.log(res)
                            sessionStorage.removeItem("uploadImage")
                            message.success("Posted on Facebook")
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                }
                else {
                    await axios.post(`${baseURL}/facebook/post-text`, {
                        "pageId": facebookID,
                        "accessToken": access_token,
                        "message": messages
                    })
                        .then((res) => {
                            console.log(res)
                            message.success("Posted on Facebook")
                        })
                        .catch((err) => {
                            console.log(err)
                        })

                }
            }
            else if (Paltform == "Youtube") {
                const formData = new FormData();
                formData.append('access_token', yt_access_token)
                formData.append('file', Fileupload)
                formData.append('token_type', 'Bearer')
                formData.append('scope', scope)
                formData.append('expiry_date', ytexpiry)
                formData.append('title', title)
                formData.append('description', messages)
                formData.append('tags', tags)
                formData.append('category_id', category)
                formData.append('privacy_status', privacyStatus)

                // console.log(formData)


                await axios.post(`${baseURL}/youtube/upload-video`, formData)
                    .then((res) => {
                        console.log(res)
                        message.success("Video Posted on Youtube")
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
            else if (Paltform == "Instagram") {
                if (PublishType == "REELS") {
                    await axios.post(`${baseURL}/instagram/post`, {
                        "video_url": sessionStorage.getItem("uploadImage"),
                        "caption": messages,
                        "accessToken": access_token,
                        "IG_USER_ID": IG_ID,
                        "media_type": PublishType,
                    })
                        .then((res) => {
                            console.log(res)
                            sessionStorage.removeItem("uploadImage")
                            message.success("Posted on Instagram")
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                }
                else {
                    await axios.post(`${baseURL}/instagram/post`, {
                        "imageUrl": sessionStorage.getItem("uploadImage"),
                        "caption": messages,
                        "accessToken": access_token,
                        "IG_USER_ID": IG_ID,
                        "media_type": PublishType,
                    })
                        .then((res) => {
                            console.log(res)
                            sessionStorage.removeItem("uploadImage")
                            message.success("Posted on Instagram")
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                }
            }
        }
    }

    const UploadImage = async (image) => {
        const formData = new FormData();
        var imagearray = [];
        formData.append("file", image);
        formData.append("upload_preset", "bwvspbg8");
        formData.append("cloud_name", "dns3xolpq");

        if (PublishType == "REELS") {
            await axios.post("https://api.cloudinary.com/v1_1/dns3xolpq/video/upload", formData)
                .then((res) => {
                    if (sessionStorage.getItem("uploadImage") !== null) {
                        imagearray = JSON.parse(sessionStorage.getItem("uploadImage"))
                        imagearray.push(res.data.secure_url)
                        sessionStorage.setItem("uploadImage", JSON.stringify(imagearray))
                    }
                    else {
                        imagearray.push(res.data.secure_url)
                        sessionStorage.setItem("uploadImage", JSON.stringify(imagearray))
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
        else {
            var imagearray = [];

            await axios.post("https://api.cloudinary.com/v1_1/dns3xolpq/image/upload", formData)
                .then((res) => {
                    if (sessionStorage.getItem("uploadImage") !== null) {
                        imagearray = JSON.parse(sessionStorage.getItem("uploadImage"))
                        imagearray.push(res.data.secure_url)
                        sessionStorage.setItem("uploadImage", JSON.stringify(imagearray))
                        uploadedImg.push(imagearray)
                    }
                    else {
                        imagearray.push(res.data.secure_url)
                        sessionStorage.setItem("uploadImage", JSON.stringify(imagearray))
                        uploadedImg.push(imagearray)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }


    }

    const AddEmoji = (emojiObject) => {
        const cursor = messageRef.current.selectionStart;
        const text = messages.slice(0, cursor) + emojiObject.emoji + messages.slice(cursor);
        console.log(text)
        setMessage(text);
        setEmoji(false)
    }

    const PreviewLogo = (event) => {
        if (event.target.files && event.target.files[0]) {
            const preview = URL.createObjectURL(event.target.files[0])
            setPhotoPreview(preview)
        }
    }

    const changeDateFormate = (date) => {
        const scheduledDate = new Date(date);
        // 2024-01-17T22:50:00Z
        const scheduleDate = scheduledDate.getFullYear() + "-" + ("0" + (scheduledDate.getMonth())).slice(-2) + "-" + "0" + scheduledDate.getDate() + "T" + scheduledDate.getHours() + ":" + scheduledDate.getMinutes() + ":" + scheduledDate.getSeconds();

        setScheduleDate(scheduleDate)
    }

    const dragNdrop = () => {
        return (

            <div className='document-sec'>
                {/* <img src={require('../Assets/twitt-pic.png')} className='twitt-pic' /> */}
                <div className='uplode-file'>Drag & drop Or
                    <button className='link-btn' onClick={() => { uploadfile.current.click() }}>select a file</button>
                </div>
            </div>
        )
    }

    const handelUploaded = (file) => {
        console.log(file)
    }

    const initialConfig = {
        namespace: 'MyEditor',
    };

    useEffect(() => {
        changeDateFormate(new Date())
        getTokens()
    }, [])

    return (
        <div>
            <div className='row no-gutter mx-0'>
                <div className='col-sm-2 head-1'>New Post</div>
                <div className='col-lg-9'></div>
            </div>
            <hr />

            <div className='row no-gutter mx-0'>

                <div className='col-sm-8 twit-box1 mb-3'>
                    {/* <div className='row no-gutter mx-0'>
                <div className='col-lg-4 box1-text' >Create Post</div>
                <div className='col-lg-5'></div>
                <div className='col-lg-3 box1-text'>Select All</div>
            </div> */}

                    <div className='d-flex mt-5'>

                        <div>
                            <button className='btn' onClick={() => { setPlatform('Twitter') }} >
                                {
                                    Paltform == "Twitter"
                                        ?
                                        <img src={require('../Assets/Twitter_switch.png')} className='Tprofile' />
                                        :
                                        <img src={require('../Assets/Profile Switches1.png')} className='Tprofile' />
                                }
                            </button>
                        </div>

                        <div>
                            <button className='btn' onClick={() => { setPlatform('Instagram') }}>
                                {
                                    Paltform == "Instagram"
                                        ?
                                        <img src={require('../Assets/Instagram_Switch.png')} className='Tprofile' />
                                        :
                                        <img src={require('../Assets/Profile Switches6.png')} className='Tprofile' />
                                }
                            </button>
                        </div>

                        <div>
                            <button className='btn' onClick={() => { setPlatform('Facebook') }}>
                                {
                                    Paltform == "Facebook"
                                        ?
                                        <img src={require('../Assets/Facebook_switch.png')} className='Tprofile' />
                                        :
                                        <img src={require('../Assets/Profile Switches2.png')} className='Tprofile' />
                                }
                            </button>
                        </div>

                        <div >
                            <button className='btn' onClick={() => { setPlatform('Linkedin') }}>
                                {
                                    Paltform == "Linkedin"
                                        ?
                                        <img src={require('../Assets/Linkedin_switch.png')} className='Tprofile' />
                                        :
                                        <img src={require('../Assets/Profile Switches3.png')} className='Tprofile' />
                                }
                            </button>
                        </div>

                        <div >
                            <button className='btn' onClick={() => { setPlatform('Youtube') }}>
                                {
                                    Paltform == "Youtube"
                                        ?
                                        <img src={require('../Assets/Youtube_switch.png')} className='Tprofile' />
                                        :
                                        <img src={require('../Assets/Profile Switches4.png')} className='Tprofile' />
                                }
                            </button>
                        </div>

                        <div >
                            <button className='btn' onClick={() => { setPlatform('GMB') }}>
                                {
                                    Paltform == "GMB"
                                        ?
                                        <img src={require('../Assets/GMB_switch.png')} className='Tprofile' />
                                        :
                                        <img src={require('../Assets/Profile Switches5.png')} className='Tprofile' />
                                }
                            </button>
                        </div>


                        {/* <div className='col-sm-6'></div> */}

                    </div>

                    <div className='d-flex'>
                        {/* different type for platforms */}
                        <div >
                            <button style={{ border: "hidden", background: "#fff", marginTop: "15" }}>
                                {
                                    Paltform == "Twitter"
                                        ?
                                        <img src={require('../Assets/twitter_logo.png')} className='twitter-cross' />
                                        :
                                        Paltform == "Instagram"
                                            ?
                                            <img src={require('../Assets/Insta_logo.png')} className='twitter-cross' />
                                            :
                                            Paltform == "Facebook"
                                                ?
                                                <img src={require('../Assets/FB_logo.png')} className='twitter-cross' />
                                                :
                                                Paltform == "Linkedin"
                                                    ?
                                                    <img src={require('../Assets/Linkedin_logo.png')} className='twitter-cross' />
                                                    :
                                                    Paltform == "Youtube"
                                                        ?
                                                        <img src={require('../Assets/Youtube_logo.png')} className='twitter-cross' />
                                                        :
                                                        <img src={require('../Assets/GMB_logo.png')} className='twitter-cross' />

                                }
                            </button>
                        </div>

                        <div className='w-100'>
                            <div className='social_media'>
                                {
                                    Paltform == "Instagram"
                                        ?
                                        <div className='d-flex align-items-center justify-content-between w-50'>
                                            <div className='d-flex algin-items-center '>
                                                <input type="radio" name='post_type' checked={PublishType == "IMAGE" ? true : false} onClick={() => { setPublishType('IMAGE') }} />
                                                <label className='px-2'>Post</label>
                                            </div>

                                            <div className='d-flex algin-items-center'>
                                                <input type="radio" name='post_type' checked={PublishType == "REELS" ? true : false} onClick={() => { setPublishType('REELS') }} />
                                                <label className='px-2'>Reel</label>
                                            </div>

                                            <div className='d-flex algin-items-center'>
                                                <input type="radio" name='post_type' />
                                                <label className='px-2'>
                                                    Story
                                                </label>
                                            </div>
                                        </div>
                                        :
                                        Paltform == "Facebook"
                                            ?
                                            <div className='d-flex align-items-center justify-content-between w-50'>
                                                <div className='d-flex algin-items-center '>
                                                    <input type="radio" name='post_type' checked={PublishType == "IMAGE" ? true : false} onClick={() => { setPublishType('IMAGE') }} />
                                                    <label className='px-2'>Post</label>
                                                </div>

                                                <div className='d-flex algin-items-center'>
                                                    <input type="radio" name='post_type' checked={PublishType == "REELS" ? true : false} onClick={() => { setPublishType('REELS') }} />
                                                    <label className='px-2'>Reel</label>
                                                </div>
                                            </div>
                                            :
                                            Paltform == "Youtube"
                                                ?
                                                <div className='d-flex align-items-center justify-content-between w-50'>
                                                    <div className='d-flex algin-items-center '>
                                                        <input type="radio" name='post_type' />
                                                        <label className='px-2'>Short</label>
                                                    </div>
                                                </div>
                                                :
                                                null
                                }
                            </div>

                            <div className='box1-small'>
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <textarea value={messages} onChange={(e) => { setMessage(e.target.value); }} ref={messageRef} placeholder='Start writing or' style={{ color: "#59626a", fontSize: "12", marginTop: "10", border: 'none', width: '70%', minHeight: '80px', maxHeight: '80px', resize: 'none' }}>
                                    </textarea>
                                    {/* <ReactQuill style={{ maxHeight: '100px' }} theme="snow" ref={messageRef} value={messages} onChange={(e) => { setMessage({ __html: e }) }} /> */}
                                    <div>
                                        <button className='assist-btn'>Use the AI Assistant</button>
                                    </div>
                                </div>

                                <div className='d-flex align-items-center' style={{ flexBasis: 0 }}>
                                    {
                                        uploadedImg.length == 0
                                            ?
                                            null
                                            :
                                            uploadedImg.map((elem, index) => {
                                                return (
                                                    <div style={{ width: '100px' }}>
                                                        <img src={elem[index]} className='uploaded_imgs' />
                                                    </div>
                                                )
                                            })
                                    }
                                    <FileUploader multiple={true} children={dragNdrop()} handleChange={handelUploaded} name="file" types={fileTypes} />
                                </div>

                            </div>
                        </div>
                    </div>

                    {/* Options */}
                    <div style={{ display: "flex", flexDirection: "row", marginTop: '10px', alignItems: "center", marginLeft: '20px' }}>

                        <div>
                            <button className='emoji_btn' onClick={() => { setUploadMenu(!uploadMenu) }}>
                                <img src={require('../Assets/upload_btn.png')} />
                            </button>

                            {
                                uploadMenu
                                    ?
                                    <>
                                        <div className='transprentoverlay' onClick={() => setUploadMenu(false)}></div>
                                        <div className='upload_menu'>
                                            <ul>
                                                <li onClick={() => { uploadfile.current.click() }}>
                                                    <i class="fa-solid fa-image mx-1"></i>
                                                    Upload Image
                                                </li>

                                                <li onClick={() => { uploadfile.current.click() }}>
                                                    <i class="fa fa-video-camera mx-1" aria-hidden="true"></i>
                                                    Upload Video
                                                </li>
                                            </ul>
                                        </div>
                                    </>
                                    :
                                    null
                            }


                            <input type="file" style={{ display: 'none' }} ref={uploadfile} onChange={(e) => { UploadImage(e.target.files[0]); setFileUpload(e.target.files[0]); PreviewLogo(e) }} />
                        </div>

                        <div>
                            <button className='emoji_btn' onClick={() => { setEmoji(!emoji) }}>
                                <img src={require('../Assets/Icons/emoji.png')} />
                            </button>
                        </div>

                        {
                            emoji
                                ?
                                <div className='emoji_picker'>
                                    <EmojiPicker onEmojiClick={(e) => { AddEmoji(e) }} />
                                </div>
                                :
                                null
                        }

                        {/* <div>
                            <button className='emoji_btn'>
                                <i class="fa-solid fa-hashtag"></i>
                            </button>
                        </div> */}

                        {/* <div>
                            <button className='emoji_btn'>
                                <strong>Bold</strong>
                            </button>
                        </div>

                        <div>
                            <button className='emoji_btn'>
                                <i>Italic</i>
                            </button>
                        </div>

                        <div>
                            <button className='emoji_btn'>
                                <u>Underline</u>
                            </button>
                        </div> */}
                    </div>

                    {/* Other Fileds */}
                    {
                        Paltform == "Youtube"
                            ?
                            <div>
                                <div className='d-flex justify-content-between'>
                                    <div>
                                        <label>
                                            Title
                                        </label>
                                    </div>

                                    <div className='w-100'>
                                        <input type="text" className='w-100' onChange={(e) => { setTitle(e.target.value) }} />
                                    </div>
                                </div>

                                <div className='d-flex justify-content-between'>
                                    <div>
                                        <label>
                                            Tags
                                        </label>
                                    </div>

                                    <div className='w-100'>
                                        <input type="text" className='w-100' onChange={(e) => { setTags(e.target.value) }} />
                                    </div>
                                </div>

                                <div className='d-flex justify-content-between'>
                                    <div>
                                        <label>
                                            Category
                                        </label>
                                    </div>

                                    <div className='w-100'>
                                        <input type="text" className='w-100' onChange={(e) => { setCategory(e.target.value) }} />
                                    </div>
                                </div>

                                <div className='d-flex justify-content-between'>
                                    <div>
                                        <label>
                                            Privacy Status
                                        </label>
                                    </div>

                                    <div className='w-100'>
                                        <input type="text" className='w-100' onChange={(e) => { setprivacyStatus(e.target.value) }} />
                                    </div>
                                </div>
                            </div>
                            :
                            null
                    }

                    <div style={{ marginTop: "35px", marginBottom: '20px' }}>
                        <hr />
                        <div className='d-flex'>
                            <div className='col-sm-5 date'>Schedule Date: <b>
                                {
                                    scheduleDates
                                        ?
                                        MonthName[(new Date(scheduleDates.split("T")[0]).getMonth() + 1).toLocaleString('default', { month: 'short' })] + " " + (new Date(scheduleDates.split("T")[0]).getDate()) + ", " + (scheduleDates.split("T")[1]).split(":")[0] + ":" + (scheduleDates.split("T")[1]).split(":")[1]
                                        :
                                        null

                                }
                            </b>
                                <button className='edit-btn' onClick={() => { setDatePickerToggle(true) }}>Edit</button>
                                {/* <input type="date"ref={scheduleDateRef}/> */}
                                <DatePicker
                                    open={datepickertoggle}
                                    format='YYYY-MM-DD HH:mm:ss'
                                    onChange={(e) => {
                                        changeDateFormate(e.$d)
                                        // setScheduleDate(e.$d)
                                    }}
                                    onOk={() => { setDatePickerToggle(false) }}
                                    style={{ visibility: 'hidden' }}
                                    showTime={true}
                                />
                            </div>

                            <div className='col-sm-3'></div>

                            <div className='col-sm-2 '>
                                <button className='draft-btn'>Schedule Draft</button>
                            </div>

                            <div className='col-sm-2'>
                                <button className='draft-btn' onClick={() => { PostFacebook() }}>Schedule Post</button>
                            </div>
                        </div>
                    </div>

                </div>

                <div className='col-sm-4 twit-box2'>
                    <div className='box2-head d-flex flex-column justify-content-between'>
                        <div className='head-1'>{Paltform}  Preview</div>
                        <div className='mt-3'>
                            {/* <div className='mx-3'>
                                {
                                    Fileupload && PublishType == "IMAGE"
                                        ?
                                        <img src={PhotoPreivew} style={{ width: "100%", borderRadius: '10px' }} />
                                        :
                                        PublishType == "REELS"
                                            ?
                                            <video src={sessionStorage.getItem("uploadImage")}>
                                            </video>
                                            :
                                            null
                                }
                            </div> */}

                            <div className='d-flex align-items-center flex-column'>
                                {
                                    Paltform == "Facebook"
                                        ?
                                        <FacebookModal Previewimages={PhotoPreivew} Previewcaptions={messages}/>
                                        :
                                        null
                                }

                                {
                                    Paltform == "Instagram"
                                        ?
                                        <InstagarmModal />
                                        :
                                        null
                                }
                            </div>
                        </div>
                    </div>

                    <div>
                        {/* <img src={require('../Assets/Twitter-box2.png')} className='Twit-box2Img' /> */}
                    </div>
                </div>

            </div>
        </div>
    )
}
