import logo from './logo.svg';
import './App.css';
import ConnectProfile from './Components/ConnectProfile';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Notification from './Components/Notification';
import CreateCampagin from './Components/CreateCampagin';
import Signup from './Components/Signup';
import Frame from './Components/Frame'
import Onboarding from './Components/Onboarding';
import Login from './Components/Login'
import Dashboard from './Components/Dashboard';
import Approval from './Components/Approval';
import Photographer from './Components/Photographer'
import Billing from './Components/Billing'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PrivateRoute from './Components/Auth/PrivateRoute';
import { useState } from 'react';
import { useEffect } from 'react';
// import { GoogleOAuthProvider } from '@react-oauth/google';
import '@progress/kendo-theme-default/dist/all.css';
import Organization from './Components/Organization';
import ProfileConnect from './Components/ProfileConnect';
import { baseURL } from './Components/config';
import axios from 'axios';
import AssetsData from './Components/AssetsData';

function App() {

  function DeleteUserData(storage) {
    if (storage == "locaStorage") {
      axios.get(`${baseURL}/user/profile/${localStorage.getItem("Email")}`)
        .then((res) => {
          if (res.data.user.status !== "completed") {
            if (storage == "localStorage") {
              localStorage.removeItem("Email");
            }
            else {
              sessionStorage.removeItem("Email")
            }
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
    else {
      axios.get(`${baseURL}/user/profile/${sessionStorage.getItem("Email")}`)
        .then((res) => {
          if (res.data.user.status !== "completed") {
            if (storage == "localStorage") {
              localStorage.removeItem("Email");
              localStorage.removeItem("user_Token");
            }
            else {
              sessionStorage.removeItem("Email");
              sessionStorage.removeItem("user_Token");
            }
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }

  }

  function checkLogin() {


    if (localStorage.getItem("user_Token")) {
      // setPrivate(true);
      DeleteUserData("localStorage")
      return true;
    }
    else if (sessionStorage.getItem('user_Token')) {
      // setPrivate(true)
      DeleteUserData("sessionStorage")
      return true;
    }
    else {
      // setPrivate(false)
      return false
    }

  }

  useEffect(() => {
    checkLogin()
    
  }, [])

  return (
    <div className="App">
      {/* <GoogleOAuthProvider clientId="851700100271-2vesukm7ef5fqeijpg8ilg5p76el9u3k.apps.googleusercontent.com"> */}
        <BrowserRouter>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          {/* Same as */}
          <ToastContainer />
          <Routes>
            {/* <Route exact path="/" element={<PrivateRoute />}>
            <Route exact path='/' element={<Login/>} />
          </Route> */}
            <Route path="/" element={checkLogin() != true ? <Login /> : <Navigate to="/Dashboard" />} />
            <Route path="/Signup" element={checkLogin() != true ? <Signup /> : <Navigate to="/Dashboard" />} />

            <Route path='/Dashboard' element={checkLogin() == true ? <Dashboard /> : <Navigate to="/" />} />
            <Route path='/Dashboard/*' element={checkLogin() == true ? <Dashboard /> : <Navigate to="/" />} />
            <Route path="/Onboarding" element={<Onboarding />} />
            <Route path="/Organization" element={<Organization />} />
            <Route path="/AssetsData" element={<AssetsData />} />
            <Route path="/Photographer" element={checkLogin() == true ? <Photographer /> : <Navigate to="/" />} />
            <Route path="/ProfileConnect" element={<ProfileConnect />} />

            <Route path="/ConnectProfile" element={<ConnectProfile />} />
            <Route path="/Notification" element={<Notification />} />
            <Route path="/Frame" element={<Frame />} />
          </Routes>
        </BrowserRouter>
      {/* </GoogleOAuthProvider> */}
    </div>
  );
}

export default App;
