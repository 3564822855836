import React from 'react'

export default function AccountSetting() {
  return (
    <div className='d-flex flex-column justify-content-center align-items-center'>
        <div className='acc-head'>Account</div>

        <div className='account-box'>
            <div className='acc-email'>Email Address</div>
            
            <div className='account-input'>
                <input type='text' id="email_id" className='Acc-input-field'></input>
            </div>
            <hr/>

            <div className='row'>
                <div className='col-6'></div>
                
                <div className='col-3'>
                    <button className='acc-btn1'>Verify Email</button>
                </div>
                
                <div className='col-3'>
                    <button className='acc-btn2'>Save Changes</button>
                </div>
            </div>
        </div>

        <div className='account-box2'>
            <div className='acc-email'>Password</div>
            
            <div className='account-input'>
                <input type='text' id="email_id" className='Acc-input-field'></input>
            </div>

            <div className='pass-text'>If you’d like to change your password, enter a new password here</div>
            <hr/>

            <div className='row'>
                <div className='col-9'></div>
                
                <div className='col-3'>
                    <button className='acc-btn2'>Change Password</button>
                </div>
            </div>
        </div>

        <div className='d-flex delete-btn-box'>
            <button className='acc-dlt'>Delete My Account</button>
        </div>

        <div className='acc-head'>
            Billing
        </div>

        <div className='account-box3'>
            <div className='acc-box3-text'>Plans</div>
        </div>

        <div className='account-box4'>
            <div className='acc-box3-text'>Basics + Trial</div>
            <div className='acc-box4-text'>Your Free Trial ends in 7 days on 14 February 2024</div>
        </div>
    </div>
  )
}

