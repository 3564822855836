import { configureStore } from "@reduxjs/toolkit";
import { DateSlice } from "./Slices/DateSlice";
import { UntilDateSlice } from "./Slices/UntilDateSlice";
import { SideSlice } from "./Slices/SIdeSlice";

const store=configureStore({
    reducer:{
        SinceDates:DateSlice.reducer,
        UntilDates:UntilDateSlice.reducer,
        SideBarName:SideSlice.reducer
    }
})

export default store
