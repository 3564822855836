import React, { useEffect, useRef, useState } from 'react'
import { Uploader } from "uploader";
import { UploadButton } from "react-uploader";
import Steps from '../Steps';
import { useNavigate } from 'react-router-dom';
import { HexColorPicker } from "react-colorful";
import FontPicker from '@aw-web-design/font-picker-react';
import { baseURL } from './config';
import axios from 'axios';
import { message, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

export default function AssetsData() {

    const navigate = useNavigate();

    const [open, setopen] = useState(false);
    const [color, setColor] = useState(null);
    const [showFonts, setShowfonts] = useState();

    const LogoRef = useRef(null);
    const PhotoRef = useRef(null);
    const VideoRef = useRef(null);


    const [BrandLogo, setBrandLogo] = useState();
    const [Photo, setPhoto] = useState();
    const [video, setVideo] = useState();
    const [fonts, setFonts] = useState();

    const [LogoPreview, setLogoPeview] = useState();
    const [PhotoPreview, setPhotoPreview] = useState();
    const [videoPreview, setvideoPreview] = useState();

    const [isLoader, setIsLoader] = useState(false);

    const openBox = () => {
        setopen(!open);
    }

    const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />;


    const uploader = Uploader({
        apiKey: "public_W142iQ77rdJthzxMyaM2XmXsM9c7" // Get production API keys from Bytescale
    });

    // Configuration options: https://www.bytescale.com/docs/upload-widget/frameworks/react#customize
    const options = { multi: true };

    // function uploadAssets() {
    //     navigate('/Dashboard/Profile')
    // }

    const uploadAssets = async () => {
        let email;
        setIsLoader(true)
        if (localStorage.getItem("Email")) {
            email = localStorage.getItem("Email")
        }
        else {
            email = sessionStorage.getItem("Email")
        }

        const formData = new FormData()

        formData.append("email_id", email);
        formData.append("brand_logo", BrandLogo);
        formData.append("brand_photos", Photo);
        formData.append("brand_videos", video);
        formData.append("brand_color", color);
        formData.append("brand_font", fonts);

        await axios.post(`${baseURL}/onboarding3/add-onboarding3`, formData)
            .then((res) => {
                UpdateStatus()
                setIsLoader(false)
            })
            .catch((err) => {
                console.log(err);
                setIsLoader(false)
                message.error("There is some error please try again")
            })
    }

    async function UpdateStatus() {
        var email;
        if (localStorage.getItem('Email')) {
            email = localStorage.getItem('Email');
        }
        else {
            email = sessionStorage.getItem('Email');
        }
        await axios.patch(`${baseURL}/user/update-status/${email}`, {
            status: 'completed'
        })
            .then((res) => {
                navigate("/Dashboard");
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const PreviewLogo = (event) => {
        if (event.target.files && event.target.files[0]) {
            const preview = URL.createObjectURL(event.target.files[0])
            setLogoPeview(preview)
        }
    }

    const PreviewPhoto = (event) => {
        if (event.target.files && event.target.files[0]) {
            const preview = URL.createObjectURL(event.target.files[0])
            setPhotoPreview(preview)
        }
    }

    const PreviewVideo = (event) => {
        if (event.target.files && event.target.files[0]) {
            const preview = URL.createObjectURL(event.target.files[0])
            setvideoPreview(preview)
        }
    }

    return (
        <div className='bg-images'>
            <div className='onbording_box'>

                <div className='steps_main_container'>
                    <Steps steps={2} />
                </div>

                <div className='form_section'>
                    <div className='onboard-line2'>Asset Library</div>


                    <div className='d-flex flex-column form_fields py-1'>
                        <label>
                            Brand Logo
                        </label>
                        {/* <UploadButton uploader={uploader}
                                options={options}
                                onComplete={files => alert(files.map(x => x.fileUrl).join("\n"))}>
                                {({ onClick }) =>
                                    
                                }
                            </UploadButton> */}

                        <div className='upload_Logo' onClick={() => { LogoRef.current.click() }}>
                            {
                                BrandLogo
                                    ?
                                    <img src={LogoPreview} style={{ width: '50%', borderRadius: '10px' }} />
                                    :
                                    <>
                                        <i className="fas fa-cloud-upload"></i>
                                        <label>
                                            Upload Logo
                                        </label>
                                    </>
                            }
                        </div>

                        <div>
                            <input type="file" onChange={(e) => { setBrandLogo(e.target.files[0]); PreviewLogo(e) }} ref={LogoRef} accept="image/*" style={{ display: 'none' }} />
                        </div>
                    </div>






                    <div className='d-flex flex-column form_fields py-1'>
                        <label>
                            Color Palette
                        </label>

                        <div className='upload_Logo upload_color d-flex flex-row align-items-center justify-content-center'>
                            {
                                color != null
                                    ?
                                    <div className='d-flex flex-column px-3'>
                                        <div className='selected_color' style={{ backgroundColor: `${color}` }}>
                                            <div className='d-flex flex-column add_color_section'>
                                            </div>
                                        </div>
                                        <div className='d-flex flex-column add_color_section'>
                                            <label>
                                                {color}
                                            </label>
                                        </div>
                                    </div>
                                    :
                                    null
                            }

                            <div className='d-flex flex-column add_color_section align-items-center'>
                                <button className='add_color_btn' onClick={() => openBox()}>
                                    {
                                        open
                                            ?
                                            <i className="fa fa-times" aria-hidden="true"></i>
                                            :
                                            <i className="fa fa-plus" aria-hidden="true"></i>
                                    }
                                </button>
                                <label>
                                    {
                                        open
                                            ?
                                            "Close"
                                            :
                                            "Add New"
                                    }
                                </label>
                            </div>

                            <div className='color_selection'>
                                {
                                    open && <HexColorPicker color={'#ffffff'} onChange={(e) => { setColor(e) }} />
                                }
                            </div>
                        </div>
                    </div>

                    <div className='d-flex flex-column form_fields py-1'>
                        <label>
                            Fonts
                        </label>

                        <div className='upload_Logo d-flex flex-row align-items-center justify-content-center'>

                            <div className='mx-3'>
                                <p className='apply-font'>
                                    {
                                        fonts
                                    }
                                </p>
                            </div>


                            <div className='d-flex flex-column add_color_section align-items-center'>
                                {
                                    showFonts
                                        ?
                                        <FontPicker
                                            apiKey="AIzaSyAub6MjUwcD5fmBd8zZpCzLbHoDfnsMJW8"
                                            activeFontFamily={"Open Sans"}
                                            onChange={(nextFont) => {
                                                setFonts(nextFont.family)
                                                setShowfonts(false)
                                            }
                                            }
                                        />
                                        :
                                        <>
                                            <button className='add_color_btn' onClick={() => { setShowfonts(!showFonts) }}>
                                                <i className="fa fa-plus" aria-hidden="true"></i>
                                            </button>
                                            <label>
                                                Add New
                                            </label>
                                        </>

                                }

                            </div>
                        </div>
                    </div>

                    <div className='d-flex flex-column form_fields py-1'>
                        <label>
                            Photos
                        </label>

                        <div className='upload_Logo' onClick={() => { PhotoRef.current.click() }}>
                            {
                                Photo
                                    ?
                                    <img src={PhotoPreview} style={{ width: '50%', borderRadius: '10px' }} />
                                    :
                                    <>
                                        <i className="fas fa-cloud-upload"></i>
                                        <label>
                                            Upload Photos
                                        </label>
                                    </>
                            }

                        </div>

                        <div>
                            <input type="file" onChange={(e) => { setPhoto(e.target.files[0]); PreviewPhoto(e) }} ref={PhotoRef} accept="image/*" style={{ display: 'none' }} />
                        </div>
                    </div>

                    <div className='d-flex flex-column form_fields py-1'>
                        <label>
                            Videos
                        </label>

                        <div className='upload_Logo' onClick={() => VideoRef.current.click()}>
                            {
                                video
                                    ?
                                    <video src={videoPreview} width="400" controls >
                                    </video>
                                    :
                                    <>
                                        <i className="fas fa-cloud-upload"></i>
                                        <label>
                                            Upload Videos
                                        </label>
                                    </>
                            }
                        </div>


                        <div>
                            <input type="file" onChange={(e) => { setVideo(e.target.files[0]); PreviewVideo(e) }} ref={VideoRef} accept="video/mp4,video/x-m4v,video/*" style={{ display: 'none' }} />
                        </div>
                    </div>
                </div>

                <div className='d-flex align-items-center justify-content-between w-100 mt-3 px-5 py-3'>
                    <div className='back_btn_section'>
                        <button className='back_btn' onClick={() => { navigate('/Organization') }}>
                            Back
                        </button>
                    </div>

                    <div className='back_btn_section' onClick={() => { uploadAssets() }}>
                        <button className='next_btn'>
                            {
                                isLoader
                                    ?
                                    <Spin indicator={antIcon} />
                                    :
                                    "Next"

                            }
                        </button>
                    </div>
                </div>
            </div>
        </div >
    )
}
