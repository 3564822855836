import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useSelector } from 'react-redux'
import { baseURL } from './config';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import {Months} from './MonthsString'
// import faker from 'faker';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

export default function ChartSection() {
    const [InstaGrowth, setInstagrwoth] = useState([]);
    const [isLoader, setIsloader] = useState(true);
    const [FBAccessToken, setFBAccessToken] = useState('');
    const [FBMediaId, setMediaId] = useState('');
    const [subscriberGained, setSubscriberGained] = useState([]);
    const [YTsubscriber, setytSubscriber] = useState([]);

    const [FbGrowth, setFBGrowth] = useState([]);

    const [AudienceGrowths, setAudienceGrowths] = useState(null);
    const Sinceselector = useSelector((state) => {
        return state.SinceDates
    });

    const untilSelector = useSelector((state) => {
        return state.UntilDates
    })

    const AudienceGrowth = async (MediaId, Tokens) => {
        InstaGrowth.length = 0
        const since = new Date(Sinceselector[0] * 1000)
        const until = new Date(untilSelector[0] * 1000);


        await axios.post(`${baseURL}/instagram/follower-unfollower`, {
            accessToken: "EAACyZBrKYZC58BO6taabgLzA89w0fNAe57ZCXVsOSmzH4cWAJhe5VLS8pRvZBMR6yZAkArNmUNEsUggRUt5mvC0FnWrojmZBo30UX2ASsz62JNkmt775FiH5gzDQHef5WbZBuPvFYsZAZBtaLjWqpEqngbKZC8a8YcKS1CCcJAyyOWhwT5UG8VNAkJdfFsh8EwXuDql1cSqVMn",
            mediaId: "17841433401221135",
            since: since.getFullYear() + "-" + (since.getMonth() + 1).toString().padStart(2, "0") + "-" +since.getDate().toString().padStart(2, "0"),
            until: until.getFullYear() + "-" + (until.getMonth() + 1).toString().padStart(2, "0") + "-" + until.getDate().toString().padStart(2, "0")
        })
            .then((res) => {
                (res.data).map((elem) => {
                    if (elem.data.data[0].total_value.breakdowns[0].results !== undefined) {
                        if (elem.data.data[0].total_value.breakdowns[0].results[1].value == 0) {
                            InstaGrowth.push(
                                {
                                    x: (elem.date).split("-")[1] + "/" + (elem.date).split("-")[2],
                                    y: elem.data.data[0].total_value.breakdowns[0].results[0].value
                                },
                                {
                                    x: (elem.date).split("-")[1] + "/" + (elem.date).split("-")[2],
                                    y: parseInt(elem.data.data[0].total_value.breakdowns[0].results[1].value)
                                }
                            )
                        }
                        else {
                            InstaGrowth.push(
                                {
                                    x: (elem.date).split("-")[1] + "/" + (elem.date).split("-")[2],
                                    y: elem.data.data[0].total_value.breakdowns[0].results[0].value
                                },
                                {
                                    x: (elem.date).split("-")[1] + "/" + (elem.date).split("-")[2],
                                    y: parseInt("-" + elem.data.data[0].total_value.breakdowns[0].results[1].value)
                                }
                            )
                        }
                    }   

                })

                setTimeout(() => {
                    setIsloader(true);
                }, 5000);

            })
            .catch((err) => {
                setIsloader(true);
                console.log(err);
            })
    }

    const facebookGrwoth = async (MediaId, Tokens) => {
        FbGrowth.length = 0
        setIsloader(false)
        await axios.post(`${baseURL}/facebook/metrics`, {
            mediaId: MediaId,
            accessToken: Tokens,
            since: Sinceselector[0],
            until: untilSelector[0]
        })
            .then((res) => {
                // console.log("facebookdata",("0" + this.getDate()).slice(-2))
                const currentMonth = (new Date(Sinceselector[0] * 1000).getMonth()) + 1
                const currentDate = (new Date(Sinceselector[0] * 1000).getDate())
                FbGrowth.push({
                    x: ("0" + currentMonth).slice(-2) + "/" + ("0" + currentDate).slice(-2),
                    y: 0
                })
                for (var i = 0; i < (res.data.data[0].values).length; i++) {
                    if (res.data.data[2].values[i].value == 0) {
                        FbGrowth.push(
                            {
                                x: (res.data.data[0].values[i].end_time).split("T")[0].split("-")[1] + "/" + (res.data.data[0].values[i].end_time).split("T")[0].split("-")[2],
                                y: res.data.data[0].values[i].value
                            },
                            {
                                x: (res.data.data[2].values[i].end_time).split("T")[0].split("-")[1] + "/" + (res.data.data[2].values[i].end_time).split("T")[0].split("-")[2],
                                y: res.data.data[2].values[i].value
                            }
                        )
                    }
                    else {
                        FbGrowth.push(
                            {
                                x: (res.data.data[0].values[i].end_time).split("T")[0],
                                y: res.data.data[0].values[i].value
                            },
                            {
                                x: (res.data.data[2].values[i].end_time).split("T")[0],
                                y: parseInt("-" + res.data.data[2].values[i].value)
                            }
                        )
                    }
                }
                AudienceGrowth(MediaId, Tokens)
                // setIsloader(true)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const YoutubeGrowthGain = async () => {
        const since = new Date(Sinceselector[0] * 1000)
        const until = new Date(untilSelector[0] * 1000);

        await axios.post(`${baseURL}/youtube/metrics`, {
            accessToken: "ya29.a0AfB_byAMczXSDjpSvQLJNiut9JniPPtHr1zy9DzL3-If3wiT2Nvy43E2NZBeYrtoKZUJZFHgwcl9gyndV1XNIOmtmHa3SnQKDK0YiVQo3gIMb32biD3CwN0PYsGcHFIIHFtVs6_lT128gGwM007HZBagmx508M_l_eA4aCgYKAckSAQ8SFQHGX2MiBO2nWNxY16xZYE0C_rJbdQ0171",
            channelId: "UC70m1WS2lLY_gb5hcQnhfgA",
            startDate: since.getFullYear() + "-" + (since.getMonth() + 1).toString().padStart(2, "0") + "-" + since.getDate().toString().padStart(2, "0"),
            lastDate: until.getFullYear() + "-" + (until.getMonth() + 1).toString().padStart(2, "0") + "-" + until.getDate().toString().padStart(2, "0"),
            metric: "subscribersGained"
        })
            .then((res) => {
                setSubscriberGained(res.data.rows)
                YoutubeGrowthLost()
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const YoutubeGrowthLost = async () => {
        setIsloader(false)
        const since = new Date(Sinceselector[0] * 1000)
        const until = new Date(untilSelector[0] * 1000);
        await axios.post(`${baseURL}/youtube/metrics`, {
            accessToken: "ya29.a0AfB_byAMczXSDjpSvQLJNiut9JniPPtHr1zy9DzL3-If3wiT2Nvy43E2NZBeYrtoKZUJZFHgwcl9gyndV1XNIOmtmHa3SnQKDK0YiVQo3gIMb32biD3CwN0PYsGcHFIIHFtVs6_lT128gGwM007HZBagmx508M_l_eA4aCgYKAckSAQ8SFQHGX2MiBO2nWNxY16xZYE0C_rJbdQ0171",
            channelId: "UC70m1WS2lLY_gb5hcQnhfgA",
            startDate: since.getFullYear() + "-" + (since.getMonth() + 1).toString().padStart(2, "0") + "-" + since.getDate().toString().padStart(2, "0"),
            lastDate: until.getFullYear() + "-" + (until.getMonth() + 1).toString().padStart(2, "0") + "-" + until.getDate().toString().padStart(2, "0"),
            metric: "subscribersLost"
        })
            .then((res) => {
                console.log(res.data)
                for (var i = 0; i < (res.data.rows).length; i++) {
                    if (res.data.rows[i][1] == 0) {
                        YTsubscriber.push({
                            x: (res.data.rows[i][0]).split("-")[1] + "/" + (res.data.rows[i][0]).split("-")[2],
                            y: subscriberGained[i][1]
                        },
                            {
                                x: (res.data.rows[i][0]).split("-")[1] + "/" + (res.data.rows[i][0]).split("-")[2],
                                y: res.data.rows[i][1]
                            }
                        )
                    }

                    setIsloader(true)
                }
            })
            .catch((err) => {
                setIsloader(true)
                console.log(err)
            })
    }

    const GetProfile = async () => {
        await axios.get(`${baseURL}/token/get/${localStorage.getItem("Email")}`)
            .then((res) => {
                // setFBAccessToken(res.data.Tokens.fb_access_token)
                // setMediaId(res.data.Tokens.fb_id)
                facebookGrwoth(res.data.Tokens.fb_id, res.data.Tokens.fb_access_token)
                YoutubeGrowthGain()
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display: false,
                }
            }
        },
        elements: {
            point: {
                radius: 0
            },
        },
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                labels: {
                    usePointStyle: true,
                    pointStyle: 'rectRounded',
                    padding: 30
                }
            }
        }

    };

    // const labels = label;

    const data = {
        datasets: [
            {
                label: 'Facebook', // Name the series
                data: FbGrowth, // Specify the data values array
                fill: true,
                borderColor: '#6F5ED3', // Add custom color border (Line)
                backgroundColor: '#6F5ED3', // Add custom color background (Points and Fill)
                borderWidth: 1, // Specify bar border width
                tension: 0.2
            },
            {
                label: 'Instagram', // Name the series
                data: InstaGrowth, // Specify the data values array
                fill: true,
                borderColor: '#CE3665', // Add custom color border (Line)
                backgroundColor: '#CE3665', // Add custom color background (Points and Fill)
                borderWidth: 1, // Specify bar border width
                tension: 0.2
            },
            {
                label: 'Youtube', // Name the series
                data: YTsubscriber, // Specify the data values array
                fill: true,
                borderColor: '#08C4B2', // Add custom color border (Line)
                backgroundColor: '#08C4B2', // Add custom color background (Points and Fill)
                borderWidth: 1, // Specify bar border width
                tension: 0.2
            }
        ],
    };

    useEffect(() => {
        GetProfile()
    }, [Sinceselector, untilSelector])

    if (isLoader == false) {
        return (
            <div id='overlay'>
                <img src={require('../Assets/logo_overlay.png')} />
            </div>
        )
    }

    return (
        <>
            <div className='chart_section'>
                <div className='chart_heading d-flex flex-column px-3 py-3'>
                    <label className='label_head'>
                        Audience Growth
                    </label>

                    <label className='label_sub_head'>
                        See how your audience grew during the reporting period.
                    </label>
                </div>
                <div style={{ height: '40vh', paddingLeft: '30px', paddingRight: '30px', paddingTop: '40px' }}>
                    {
                        console.log(data)
                    }
                    <Line options={options} data={data} />
                </div>

                <div className='w-100 px-3'>
                    <table className='w-100 table chart_table'>
                        <tr>
                            <th>
                                Audience Metrics
                            </th>

                            <th>
                                Totals
                            </th>

                            <th>
                                % Change
                            </th>
                        </tr>

                        <tbody>
                            <tr>
                                <td>
                                    Total Audience
                                </td>

                                <td>
                                    {
                                        data.datasets[1].data.reduce((accumulator, currentValue) =>
                                            accumulator + currentValue.y, 0
                                        ) + data.datasets[0].data.reduce((accumulator, currentValue) =>
                                            accumulator + currentValue.y, 0
                                        )
                                    }
                                </td>

                                <td>
                                    2.1%
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    Total Net Audience Growth
                                </td>

                                <td>

                                </td>

                                <td>
                                    130%
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    Instagram Net Follower Growth
                                </td>
                                <td>
                                    {
                                        data.datasets[1].data.reduce((accumulator, currentValue) =>
                                            accumulator + currentValue.y, 0
                                        )
                                    }
                                </td>

                                <td>
                                    2400%
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    Facebook Net Page Likes
                                </td>
                                <td>
                                    {
                                        data.datasets[0].data.reduce((accumulator, currentValue) =>
                                            accumulator + currentValue.y, 0
                                        )
                                    }
                                </td>

                                <td>
                                    2400%
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}
