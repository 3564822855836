import React, { useRef, useState, useEffect, useCallback } from 'react'
import { baseURL } from './config'
import axios from 'axios';
import { message } from 'antd';
import FacebookLogin from '@greatsumini/react-facebook-login';
import {
    LoginSocialGoogle,
    LoginSocialInstagram,
    LoginSocialLinkedin,
    LoginSocialTwitter
} from 'reactjs-social-login';

export default function ConnectProfile() {
    const [twtoken, setTWToken] = useState(false);
    const [googleToken, setGoogleToken] = useState(false);
    const [facebookToken, setFacebookToken] = useState(false);
    const [instagramToken, setInstagramToken] = useState(false);

    const [isLoader, setLoader] = useState(false);

    const REDIRECT_URI = 'https://spaceowl.project-tester.cloud/Dashboard/Profile'
    // const REDIRECT_URI = 'https://app.oolook.in/Dashboard/Profile'

    // const REDIRECT_URI="http://localhost:3000/Dashboard/Profile"

    const AddTokens = async () => {
        var email;
        if (localStorage.getItem("Email")) {
            email = localStorage.getItem("Email");
        }
        else {
            email = sessionStorage.getItem("Email");
        }


        await axios.post(`${baseURL}/token/add`, {
            "email_id": email,
            "yt_access_token": "",
            "tw_access_token": "",
            "insta_access_token": "",
            "fb_access_token": "",
            "linkedin_access_token": "",
            "pinterest_access_token": ""
        })
            .then((res) => {
                console.log(res)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const getTokens = () => {
        const email = localStorage.getItem("Email");
        axios.get(`${baseURL}/token/get/${email}`)
            .then((res) => {
                if (res.data.Tokens.tw_access_token !== "") {
                    setTWToken(true)
                }

                if (res.data.Tokens.yt_access_token !== "") {
                    setGoogleToken(true)
                }

                if (res.data.Tokens.insta_access_token !== "") {
                    setInstagramToken(true)
                }

                if (res.data.Tokens.fb_access_token !== "") {
                    setFacebookToken(true)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const convertaccesstoken = async (token) => {
        await axios.get('https://graph.facebook.com/v14.0/me/accounts', {
            params: {
                fields: 'id,name,access_token',
                access_token: token
            }
        })
            .then((res) => {
                uploadfacebookToken(res.data.data[0].access_token, res.data.data[0].id)
            })
            .catch((err) => {
                console.log(err)
            })
    }


    const uploadfacebookToken = async (token, page_id) => {
        var email;
        setLoader(true)
        if (localStorage.getItem("Email")) {
            email = localStorage.getItem("Email");
        }
        else {
            email = sessionStorage.getItem("Email");
        }

        await axios.patch(`${baseURL}/token/add-fb/${email}`, {
            fb_access_token: token,
            fb_id: page_id
        })
            .then((res) => {
                setLoader(false)
                message.success("Facebook connected")
                getIGID(token, page_id)
                getTokens()
            })
            .catch((err) => {
                setLoader(false)
                console.log(err)
            })
    }

    const getIGID = async (access_token, fb_id) => {
        await axios.post(`${baseURL}/instagram/ig-id`, {
            id: fb_id,
            accessToken: access_token
        })
            .then((res) => {
                console.log(res)
                uploadInstagramToken(access_token, res.data.id)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const uploadInstagramToken = async (token, id) => {
        var email;
        setLoader(true)

        if (localStorage.getItem("Email")) {
            email = localStorage.getItem("Email");
        }
        else {
            email = sessionStorage.getItem("Email");
        }

        await axios.patch(`${baseURL}/token/add-insta/${email}`, {
            insta_access_token: token,
            insta_id: id
        })
            .then((res) => {
                setLoader(false)
                message.success("Instagram connected")
                getTokens()
            })
            .catch((err) => {
                setLoader(false)
                console.log(err)
            })
    }


    const uploadTwitterToken = async (token) => {
        var email;
        setLoader(true)

        if (localStorage.getItem("Email")) {
            email = localStorage.getItem("Email");
        }
        else {
            email = sessionStorage.getItem("Email");
        }

        await axios.patch(`${baseURL}/token/add-tw/${email}`, {
            tw_access_token: token
        })
            .then((res) => {
                setLoader(false)
                message.success("Twitter connected")
                getTokens()
            })
            .catch((err) => {
                setLoader(false)
                console.log(err)
            })
    }

    const uploadGoogleToken = async (token, expiry, scope) => {
        var email;
        const scopes = scope.split(" ");
        setLoader(true);
        if (localStorage.getItem("Email")) {
            email = localStorage.getItem("Email");
        }
        else {
            email = sessionStorage.getItem("Email");
        }

        await axios.patch(`${baseURL}/token/add-yt/${email}`, {
            yt_access_token: token,
            yt_scope: scopes[2],
            yt_expiry_date: expiry
        })
            .then((res) => {
                setLoader(false)
                message.success("Google connected")
                getTokens()
            })
            .catch((err) => {
                setLoader(false)
                console.log(err)
            })
    }


    useEffect(() => {
        getTokens()
        AddTokens()
    }, [])

    if (isLoader) {
        return (
            <div id='overlay'>
                <div style={{ textAlign: "center" }}>
                    <img src={require('../Assets/logo_symbol.png')} />
                </div>
            </div>
        )
    }

    return (
        <div>
            <div className='profile-connect'>
                <h2>Connect Profile</h2>
            </div>

            <hr />

            <div className='profile-section'>
                <h6>
                    Select a network to connect a profile.
                </h6>

                <p className='profile_head2'>
                    If you have several accounts to connect, don’t worry—we’ll bring you back to this screen after each profile is set up.
                </p>
            </div>

            <div className='social-links'>
                <div className='socials'>
                    <div className='social-logo'>
                        {/* <i class="fa-brands fa-facebook fa-2x" style={{color:"#1877F2"}}></i> */}
                        <img src={require('../Assets/Icons/Facebook.png')} style={{ width: "30px" }} />
                    </div>

                    <hr />
                    <div className='social-name'>
                        <label>Facebook</label>
                    </div>

                    <div style={{ display: "flex", justifyContent: 'center', padding: '10px' }}>
                        <FacebookLogin
                            appId="3569756573309673"
                            loginOptions={{
                                scope: 'email,read_insights,publish_video,pages_show_list,ads_management,business_management,pages_messaging,instagram_basic,instagram_manage_comments,instagram_manage_insights,instagram_content_publish,publish_to_groups,page_events,pages_read_engagement,pages_manage_metadata,pages_read_user_content,pages_manage_posts,pages_manage_engagement,instagram_manage_events'
                            }}
                            onSuccess={(response) => {
                                convertaccesstoken(response.accessToken)
                            }}
                            onFail={(error) => {
                                console.log('Login Failed!', error);
                            }}
                            onProfileSuccess={(response) => {
                                console.log('Get Profile Success!', response);
                            }}

                            render={({ onClick, logout }) => (
                                <button className='connect-btn' disabled={facebookToken} onClick={onClick} onLogoutClick={logout}>
                                    {
                                        facebookToken
                                            ?
                                            "Connected"
                                            :
                                            "Connect"
                                    }
                                </button>
                            )}
                        />

                    </div>
                </div>

                <div className='socials instagram'>
                    <div className='social-logo'>
                        <img src={require('../Assets/Icons/Instagram.png')} style={{ width: "30px" }} />
                    </div>

                    <hr />
                    <div className='social-name'>
                        <label>Instagram</label>
                    </div>

                    <div style={{ justifyContent: 'center', padding: '10px' }}>
                        <LoginSocialInstagram
                            client_id={"2091419804524502"}
                            client_secret={"6b905bb5a956ec125317520b027f22f3"}
                            redirect_uri={REDIRECT_URI}
                            onResolve={({ provider, data }) => {
                                uploadInstagramToken(data.access_token)
                            }}
                            onReject={(err) => {
                                console.log(err);
                            }}
                        >
                            <button className='connect-btn' disabled={instagramToken}>
                                {
                                    instagramToken
                                        ?
                                        "Connected"
                                        :
                                        "Connect"
                                }
                            </button>
                        </LoginSocialInstagram>
                    </div>
                </div>

                <div className='socials twitter'>
                    <div className='social-logo'>
                        <img src={require('../Assets/Icons/Twitter.png')} style={{ width: "30px" }} />
                    </div>

                    <hr />
                    <div className='social-name'>
                        <label>Twitter</label>
                    </div>


                    <div style={{ justifyContent: 'center', padding: '10px' }}>
                        <LoginSocialTwitter
                            client_id={process.env.REACT_APP_TWITTER_V2_APP_KEY || 'NXBfOEtyczRoU043Y1RUVFoxWWE6MTpjaQ'}
                            // client_secret={'FnHe5n3YesLmy9BteCl4UQanzBP9pAIr3mkwUqVML86oVWdDls'}
                            redirect_uri={REDIRECT_URI}
                            onResolve={({ provider, data }) => {
                                uploadTwitterToken(data.access_token)
                            }}
                            onReject={(err) => {
                                console.log(err)
                            }}
                        >
                            <button className='connect-btn' disabled={twtoken}>
                                {
                                    twtoken
                                        ?
                                        "Connected"
                                        :
                                        "Connect"
                                }
                            </button>
                        </LoginSocialTwitter>
                    </div>


                </div>
            </div>

            <div className='social-links '>
                <div className='socials linkedin'>
                    <div className='social-logo'>
                        <img src={require('../Assets/Icons/LinkedIn.png')} style={{ width: "30px" }} />
                    </div>

                    <hr />
                    <div className='social-name'>
                        <label>LinkedIn</label>
                    </div>

                    <div style={{ padding: '10px', justifyContent: 'center' }}>


                        <LoginSocialLinkedin
                            isOnlyGetToken
                            client_id={'86lz4lefn332r0'}
                            client_secret={'VcRvz75LauKLykxA'}
                            redirect_uri={REDIRECT_URI}
                            onResolve={({ provider, data }) => {
                                console.log(provider, data)
                            }}
                            onReject={(err) => {
                                console.log(err)
                            }}
                        >
                            <button className='connect-btn' >
                                Connect
                            </button>
                        </LoginSocialLinkedin>
                    </div>
                </div>

                <div className='socials googlebusiness'>
                    <div className='social-logo d-flex justify-content-center align-items-center'>
                        <img src={require('../Assets/Icons/GB.png')} style={{ width: "30px" }} />
                        <img src={require('../Assets/Icons/Youtube.png')} style={{ width: "40px" }} />

                    </div>

                    <hr />
                    <div className='social-name'>
                        <label>Google My Business / Youtube</label>
                    </div>

                    <div style={{ padding: '10px', justifyContent: 'center' }}>
                        <LoginSocialGoogle
                            // scope='https://www.googleapis.com/auth/business.manage https://www.googleapis.com/auth/youtube.readonly https://www.googleapis.com/auth/youtube.upload'
                            scope='https://www.googleapis.com/auth/business.manage https://www.googleapis.com/auth/youtube.readonly https://www.googleapis.com/auth/youtube.upload'
                            redirect_uri={REDIRECT_URI}
                            client_id={'337360509244-kj0ks7k9ti6lbfos5113akcgtefuacb3.apps.googleusercontent.com'}
                            onResolve={({ provider, data }) => {
                                uploadGoogleToken(data.access_token, data.sub, data.scope)
                            }}
                            onReject={(err) => {
                                console.log(err)
                            }}
                        >
                            <button className='connect-btn' disabled={googleToken}>
                                {
                                    googleToken
                                        ?
                                        "Connected"
                                        :
                                        "Connect"
                                }
                            </button>
                        </LoginSocialGoogle>
                    </div>
                </div>

                {/* <div className='socials youtube'>
                    <div className='social-logo'>
                        <img src={require('../Assets/Icons/Youtube.png')} style={{ width: "50px" }} />
                    </div>

                    <hr />
                    <div className='social-name'>
                        <label>Youtube</label>
                    </div>

                    <div style={{ padding: '10px', justifyContent: 'center' }}>
                        <LoginSocialGoogle
                            scope='https://www.googleapis.com/auth/business.manage https://www.googleapis.com/auth/youtube.readonly https://www.googleapis.com/auth/youtube.upload'
                            redirect_uri={REDIRECT_URI}
                            client_id={'337360509244-kj0ks7k9ti6lbfos5113akcgtefuacb3.apps.googleusercontent.com'}
                            
                            onResolve={({ provider, data }) => {
                                uploadGoogleToken(data.access_token, data.sub, data.scope)
                            }}
                            onReject={(err) => {
                                console.log(err)
                            }}
                        >
                            <button className='connect-btn' disabled={googleToken}>
                                {
                                    googleToken
                                        ?
                                        "Connected"
                                        :
                                        "Connect"
                                }
                            </button>
                        </LoginSocialGoogle>
                    </div>
                </div> */}
            </div>

        </div>
    )
}
