import React, { useEffect, useState } from 'react'
import ChartSection from './ChartSection';
import Overview from './Reports Pages/Overview';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux'
import { ChangeSinceDate } from './store/Slices/DateSlice';
import { ChangeUntilDate } from './store/Slices/UntilDateSlice';
import { StoreSideBar } from './store/Slices/SIdeSlice';
import { baseURL } from './config';
import axios from 'axios';

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY/DD/MM';

export default function ProfileReports() {
    const [profileTab, setProfileTab] = useState("Overview");
    const [until, setUntil] = useState();
    const [since, setSince] = useState();

    const [showFilter, setshowFilter] = useState(false);

    const [InstaProfiledate, setInstaPrfileData] = useState();
    const dispatch = useDispatch();

    const MonthsString = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"]


    const d = new Date();
    var months = (d.getMonth() + 1).toString().padStart(2, "0");
    var day = d.getDate().toString().padStart(2, "0");

    const Sinceselector = useSelector((state) => {
        return state.SinceDates
    });

    const untilSelector = useSelector((state) => {
        return state.UntilDates
    })

    const InstaProfile = async () => {
        await axios.post(`${baseURL}/instagram/profile`, {
            accessToken: "EAACyZBrKYZC58BO6taabgLzA89w0fNAe57ZCXVsOSmzH4cWAJhe5VLS8pRvZBMR6yZAkArNmUNEsUggRUt5mvC0FnWrojmZBo30UX2ASsz62JNkmt775FiH5gzDQHef5WbZBuPvFYsZAZBtaLjWqpEqngbKZC8a8YcKS1CCcJAyyOWhwT5UG8VNAkJdfFsh8EwXuDql1cSqVMn",
            insta_id: "17841433401221135"
        })
            .then((res) => {
                setInstaPrfileData(res.data)
            })
            .catch((err) => {
                console.log()
            })
    }

    useEffect(() => {
        dispatch(ChangeSinceDate(Date.parse(d.getFullYear() + "/" + months + "/" + '01') / 1000))
        dispatch(ChangeUntilDate(Date.parse(d.getFullYear() + "/" + months + "/" + day) / 1000))
        InstaProfile()
        dispatch(StoreSideBar("Report"))
    }, [until, since])

    return (
        <div>
            <div className='approval_header performace_header'>
                <div className='performance_inner'>
                    <div className='performace_subhead align-items-center'>
                        <div>
                            <h4>Profile Performance</h4>
                        </div>
                        <div className='mx-2'>
                            <i class="fa fa-star fa-1x" aria-hidden="true"></i>
                        </div>
                    </div>

                    <span className='date_txt'>Activity from {MonthsString[new Date(Sinceselector[0] * 1000).getMonth()] + " " + new Date(Sinceselector[0] * 1000).getDate() + ", " + new Date(Sinceselector[0] * 1000).getFullYear()} -
                        {MonthsString[new Date(untilSelector[0] * 1000).getMonth()] + " " + new Date(untilSelector[0] * 1000).getDate() + ", " + new Date(untilSelector[0] * 1000).getFullYear()}
                        <label className='highlight_txt'> {" "} ( multiple</label> time zones)</span>
                </div>

                <div className='d-flex align-items-center'>
                    <div>
                        <span className='Inbox_remaining performace_date'>
                            {/* <i class="fa fa-calendar" style={{ marginRight: '5px' }} aria-hidden="true"></i>
                            4/1/2023 – 4/30/2023

                            <label>
                                3/1/2023
                            </label>

                            <label>
                                3/31/2023
                            </label> */}

                            <RangePicker
                                format={dateFormat}
                                style={{ backgroundColor: 'transparent', border: 'none' }}
                                defaultValue={[dayjs(d.getFullYear() + "/" + '01' + "/" + months, dateFormat), dayjs(d.getFullYear() + "/" + day + "/" + months, dateFormat)]}
                                onChange={(e) => { dispatch(ChangeSinceDate(Date.parse(e[0].$d) / 1000)); dispatch(ChangeUntilDate(Date.parse(e[1].$d) / 1000)); }} />

                        </span>
                    </div>

                    <div>
                        <span className='Inbox_remaining'>
                            <i class="fa fa-ellipsis-h"></i>
                        </span>
                    </div>

                    <div>
                        <span className='Inbox_remaining'>
                            <i class="fa fa-upload" style={{ marginRight: '5px' }}></i>
                            Export
                        </span>
                    </div>

                    <div>
                        <button className='Inbox_remaining Filter_btn' onClick={() => { setshowFilter(!showFilter) }}>
                            <img src={require('../Assets/Filter.png')} />
                            Filters
                        </button>
                    </div>
                </div>


            </div>

            {
                showFilter
                    ?
                    <table className='filter_table'>
                        <tr>
                            <td>
                                <label>Sources</label>
                                <select>
                                    <option>Viewing all</option>
                                </select>
                            </td>

                            <td>

                            </td>

                            <td>

                            </td>

                            <td className='reset_message'>
                                <button className='btn reset_btn'>
                                    Clear
                                </button>
                            </td>
                        </tr>
                    </table>
                    :
                    null
            }



            <div className='d-flex w-100'>
                <button className={profileTab == "Overview" ? 'w-50 tab_btn active_tab' : "w-50 tab_btn active_btn"} onClick={() => { setProfileTab("Overview") }}>
                    Overview
                </button>

                <button className={profileTab == "Profiles" ? 'w-50 tab_btn active_tab' : "w-50 tab_btn active_btn"} onClick={() => { setProfileTab("Profiles") }}>
                    Profiles
                </button>
            </div>

            {
                profileTab == "Overview"
                    ?
                    <Overview />
                    :
                    <ChartSection />
            }
        </div>
    )
}
