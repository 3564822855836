import React, { useEffect, useState } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { Link } from 'react-router-dom';
import Dropdown from './Filter Dropdown/Dropdown';


export default function Calender() {
    const [dataObject, setDataObject] = useState(moment());
    const [month, setMonth] = useState('');
    const [ViewType,setViewType]=useState('Month')

    const [showFilter, setShowFilter] = useState(false);

    const firstDayOfMonth = () => {
        let dateObject = dataObject;
        let firstDay = moment(dateObject)
            .startOf("month")
            .format("d");
        return firstDay;
    };

    function WeekRender() {
        const weekdayshort = moment.weekdaysShort();
        let weekdayshortname = weekdayshort.map(day => {
            return (
                <th key={day} className="week-day">
                    {day}
                </th>
            );
        });

        return weekdayshortname;
    }

    //    const currentDay = () => {  
    //         return dataObject.format("D");
    //    };


    function RenderDate() {
        // empty dates
        let blanks = [];
        for (let i = 0; i < firstDayOfMonth(); i++) {
            blanks.push(
                <td className="calendar-day empty">{""}</td>
            );
        }

        // dates
        let dates = moment(dataObject)
            .daysInMonth();
        let date = []
        for (var d = 1; d <= dates; d++) {
            if (d == dataObject.format("D")) {
                date.push(
                    <th key={d} className="weeks-day current">
                        <div style={{ textAlign: "left", height: "10%", marginBottom: '10px' }}>
                            {d}
                        </div>

                        {/* task */}
                        <div style={{ height: "90%", }}>
                            <span className='tasks'>
                                <label>
                                    <i class="fa-brands fa-facebook"></i>
                                </label>
                                <label>
                                    4
                                </label>
                            </span>

                            <span className='tasks'>
                                <label>
                                    <i class="fa-brands fa-instagram"></i>
                                </label>
                                <label>
                                    4
                                </label>
                            </span>

                            <div className='action_task'>
                                <Link className='tasks add_task' to="/Dashboard/PublishPost">
                                    <i class="fa fa-plus" aria-hidden="true"></i>
                                </Link>

                                <span className='tasks add_task'>
                                    <i class='fa fa-edit'></i>
                                </span>
                            </div>
                        </div>
                    </th>
                )
            }
            else
                if (d < dataObject.format("D")) {
                    date.push(
                        <th key={d} className="previous-weeks-day">
                            <div style={{ textAlign: "left", height: "10%", marginBottom: '10px' }}>
                                {d}
                            </div>

                            {/* task */}
                            <div style={{ height: "90%" }}>
                                <span className='tasks'>
                                    <label>
                                        <i class="fa-brands fa-facebook"></i>
                                    </label>
                                    <label>
                                        4
                                    </label>
                                </span>

                                <span className='tasks'>
                                    <label>
                                        <i class="fa-brands fa-instagram"></i>
                                    </label>
                                    <label>
                                        4
                                    </label>
                                </span>

                                <div className='action_task'>
                                    {
                                        d < dataObject.format("D")
                                            ?
                                            null
                                            :
                                            <>
                                                <Link className='tasks add_task' to="/Dashboard/Createpost">
                                                    <i class="fa fa-plus" aria-hidden="true"></i>
                                                </Link>
                                                <span className='tasks add_task'>
                                                    <i class='fa fa-edit'></i>
                                                </span>
                                            </>
                                    }

                                </div>
                            </div>
                        </th>
                    )
                }
                else
                    date.push(
                        <th key={d} className="weeks-day">
                            <div style={{ textAlign: "left", height: "10%", marginBottom: '10px' }}>
                                {d}
                            </div>

                            {/* task */}
                            <div style={{ height: "90%" }}>
                                <span className='tasks'>
                                    <label>
                                        <i class="fa-brands fa-facebook"></i>
                                    </label>
                                    <label>
                                        4
                                    </label>
                                </span>

                                <span className='tasks'>
                                    <label>
                                        <i class="fa-brands fa-instagram"></i>
                                    </label>
                                    <label>
                                        4
                                    </label>
                                </span>

                                <div className='action_task'>
                                    {
                                        d < dataObject.format("D")
                                            ?
                                            null
                                            :
                                            <>
                                                <Link className='tasks add_task' to="/Dashboard/Createpost">
                                                    <i class="fa fa-plus" aria-hidden="true"></i>
                                                </Link>
                                                <span className='tasks add_task'>
                                                    <i class='fa fa-edit'></i>
                                                </span>
                                            </>
                                    }

                                </div>
                            </div>
                        </th>
                    )
        }


        var totalSlots = [...blanks, ...date];
        let rows = [];
        let cells = [];

        totalSlots.forEach((row, i) => {
            if (i % 7 !== 0) {
                cells.push(row); // if index not equal 7 that means not go to next week
            } else {
                rows.push(cells); // when reach next week we contain all td in last week to rows 
                cells = []; // empty container 
                cells.push(row); // in current loop we still push current row to new container
            }
            if (i === totalSlots.length - 1) { // when end loop we add remain date
                rows.push(cells);
            }
        })

        let daysinmonth = rows.map((d, i) => {
            return <tr>{d}</tr>;
        });

        return daysinmonth;
    }

    const NextMonth = () => {
        setMonth(Months[moment(month).month() + 1] + ", " + moment().year());
        setDataObject(moment(Months[moment(month).month() + 1] + ", " + moment().year()))
    }

    const PreviousMonth = () => {
        setMonth(Months[moment(month).month() - 1] + ", " + moment().year());
        setDataObject(moment(Months[moment(month).month() - 1] + ", " + moment().year()))

    }

    const currentMonth = () => {
        setMonth(Months[moment().month()] + ", " + moment().year());
        setDataObject(moment())
    }

    const Months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    const FilterData = [
        [
            "Post Type", [
                "Image Posts",
                "Video Posts",
                "Textual Posts",
                "Viewing All"
            ]
        ],
        [
            "Profiles", [
                "FB Account 1",
                "FB Account 2",
                "IG Account 1",
                "Viewing All"
            ]
        ],
        [
            "Tags", [
                "Facebook",
                "Instagram",
                "LinkedIn",
                "Viewing All"
            ]
        ],
        [
            "Authors", [
                "Author 1(Oolook)",
                "Author 2 (Me)",
                "Author 3 (Team Member)",
                "Viewing All"
            ]
        ]
    ]

    useEffect(() => {
        setMonth(Months[moment(dataObject).month()] + ", " + moment().year());
    }, [])


    return (
        <div className='calender_section'>
            <div className='d-flex align-items-center justify-content-between'>
                <div className='approval_header d-flex'>
                    <button className='arrow_btn' onClick={() => { PreviousMonth(); }}>
                        <i class="fa fa-arrow-left" aria-hidden="true"></i>
                    </button>

                    <h4 className='px-3 current_month' onClick={() => { currentMonth() }}>{"Today"}</h4>

                    <button className='arrow_btn' style={{ marginRight: '10px' }} onClick={() => { NextMonth(); }}>
                        <i class="fa fa-arrow-right" aria-hidden="true"></i>
                    </button>

                    <h5>{month}</h5>

                </div>

                <div className='d-flex'>
                    <div className='d-flex menu_section mx-2'>
                        <button className='menu_btn_1 menu_active'>
                            <img src={require('../Assets/menu_1.png')} />
                        </button>

                        <button className='menu_btn_2'>
                            <img src={require('../Assets/menu_2.png')} />
                        </button>
                    </div>

                    <div className='d-flex justify-content-between menu_section mx-2'>
                        <button className={ViewType=="List"?'menu_btn_1 menu_active':'menu_btn_1 '} onClick={()=>{setViewType('List')}}>
                            List
                        </button>

                        <button className={ViewType=="Week"?'menu_btn_2 menu_active':'menu_btn_2 '} onClick={()=>{setViewType('Week')}}>
                            Week
                        </button>

                        <button className={ViewType=="Month"?'menu_btn_2 menu_active':'menu_btn_2'} onClick={()=>{setViewType('Month')}}>
                            Month
                        </button>
                    </div>

                    <div className='d-flex justify-content-between menu_section mx-2'>
                        <button className='menu_btn_2'>
                            <i class="fa fa-ellipsis-h"></i>
                        </button>

                    </div>

                    <div className='d-flex justify-content-between align-items-center px-2 menu_section mx-2'>
                        <img src={require('../Assets/share.png')} style={{ width: '20px', height: '20px' }} />
                        <button className='menu_btn_2'>
                            Share
                        </button>

                    </div>

                    <div className='d-flex justify-content-between align-items-center px-2 menu_section mx-2 menu_active'>

                        <button className='menu_btn_2 ' style={{ color: "#fff" }}>
                            Generate
                        </button>

                        <img src={require('../Assets/generate.png')} style={{ width: '20px', height: '20px' }} />
                    </div>
                </div>
            </div>

            {/* Filter List */}
            <div className='d-flex align-items-center justify-content-between filter_section'>
                <Dropdown FilterOption={FilterData[0]} />

                <Dropdown FilterOption={FilterData[1]} />

                <Dropdown FilterOption={FilterData[2]} />

                <Dropdown FilterOption={FilterData[3]} />

            </div>


            <div className='days_section'>
                <table>
                    <thead>
                        {
                            WeekRender()
                        }
                    </thead>
                    <tbody>
                        {
                            RenderDate()
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}
