import React, { useState } from 'react'

export default function InstagarmModal() {
    const [open, setOpen] = useState(true);
    return (
        <div className='w-100 d-flex flex-column align-items-center social_preview_box my-3'>
            <button onClick={() => { setOpen(!open) }} className='my-3 toggle_btn d-flex align-items-center justify-content-between'>
                <div className='d-flex align-items-center'>
                    <i class="fa-brands fa-instagram fa-lg mx-2" style={{ color: '#d62976  ' }}></i>
                    <label className='social_name'>
                        Instagram
                    </label>
                </div>

                <div>
                    <i className={open ? "fa fa-angle-up" : "fa fa-angle-down"}></i>
                </div>
            </button>

            {
                open
                    ?
                    <div className='facebook_preview'>
                        <div className='d-flex align-items-center p-2'>
                            <div>
                                <img src={require('../../Assets/grp_profile.png')} />
                            </div>

                            <div className='mx-2'>
                                <label className='userid_preview'>
                                    My Web-Space Owl
                                </label>
                            </div>
                        </div>

                        <div className='d-flex justify-content-center'>
                            <img src={require('../../Assets/shopping_bag.png')} className="previewImgs" />
                        </div>

                        <div className='d-flex justify-content-left align-items-center p-4 px-2'>
                            <div>
                                <i class="fa-regular fa-heart fa-lg mx-2"></i>
                            </div>

                            <div className='d-flex'>
                                <i class="fa-regular fa-comment fa-flip-horizontal fa-lg mx-2"></i>
                            </div>
                        </div>

                        <div className='mx-2 preview_captions'>
                            <label className='instagram_user_name'>
                                kamal_pathak_0000
                            </label>
                            captions
                        </div>

                        <div className='insta_preview_date'>
                            5 Mar
                        </div>
                    </div>
                    :
                    null
            }
        </div>
    )
}
