import React from 'react'

export default function Inbox() {
    return (
        <div>
            {/* Header */}
            <div className='approval_header message_header'>
                <h4>All Message</h4>

                <div>
                    <i class="fa fa-search"></i>
                </div>

                <div>
                    <span className='Inbox_remaining'>
                        1.2k remaining
                    </span>

                    <span className='Inbox_remaining'>
                        <i class="fa fa-calendar" style={{ marginRight: '5px' }} aria-hidden="true"></i>
                        Previous 90 days
                    </span>

                    <span className='Inbox_remaining'>
                        <i class="fa fa-ellipsis-h"></i>
                    </span>

                    <span className='Inbox_remaining'>
                        <i class="fa fa-upload" style={{ marginRight: '5px' }}></i>
                        Export
                    </span>

                    <span className='Inbox_remaining Filter_btn'>
                        <img src={require('../Assets/Filter.png')} />
                        Filters
                    </span>
                </div>
            </div>

            {/* Filter table */}
            <table className='filter_table'>
                <tr>
                    <td>
                        <label>Sources</label>
                        <select>
                            <option>Viewing all</option>
                        </select>
                    </td>

                    <td>
                        <label>Message Type</label>
                        <select>
                            <option>Viewing all</option>
                        </select>
                    </td>

                    <td>
                        <label>More Filters</label>
                        <select>
                            <option></option>
                        </select>
                    </td>

                    <td className='reset_message'>
                        <button className='btn reset_btn'>
                            Reset
                        </button>

                        <button className='Inbox_remaining'>
                            Save As...
                        </button>
                    </td>
                </tr>
            </table>

            {/* Message */}
            <div>
                <div className='detail_message_section'>
                    <div className='post_header message_detail_header'>
                        <img src={require('../Assets/IMAGE.png')} className='post_header_img' />

                        <div style={{ width: '70%' }}>
                            <div>
                                <i class="fa-brands fa-instagram"></i>
                                <label style={{ marginLeft: '10px' }}>
                                    Sujeet Ji
                                </label>
                            </div>

                            <label className='status'>
                                Direct Message to Space Owl
                            </label>
                        </div>

                        <div className='date_section'>
                            <label>
                                Sat, May 6, 2023 4:20 am
                            </label>

                            <input type="checkbox" style={{ marginLeft: '20px' }} />
                        </div>

                    </div>

                    <div className='message_detail'>
                        <p>When it comes to protecting your family, it’s crucial to make the right decision when
                            choosing the right kind of life insurance. Primerica is here to help families like yours.</p>

                        <div className='message_footer'>
                            <button className='btn view_thread_btn'>
                                View Thread
                            </button>

                            <div>
                                <button className='btn'>
                                    <i class="fa-regular fa-heart"></i>
                                </button>

                                <button className='btn'>
                                    <i class="fa-solid fa-share fa-flip-horizontal"></i>
                                </button>

                                <button className='btn'>
                                    <i class="fa fa-tag" aria-hidden="true"></i>
                                </button>

                                <button className='btn'>
                                    <i class="fa-regular fa-circle-check"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='detail_message_section'>
                    <div className='post_header message_detail_header'>
                        <img src={require('../Assets/IMAGE.png')} className='post_header_img' />

                        <div style={{ width: '70%' }}>
                            <div>
                                <i class="fa-brands fa-instagram"></i>
                                <label style={{ marginLeft: '10px' }}>
                                    Sujeet Ji
                                </label>
                            </div>

                            <label className='status'>
                                Direct Message to Space Owl
                            </label>
                        </div>

                        <div className='date_section'>
                            <label>
                                Sat, May 6, 2023 4:20 am
                            </label>

                            <input type="checkbox" style={{ marginLeft: '20px' }} />
                        </div>

                    </div>

                    <div className='message_detail'>
                        <p>When it comes to protecting your family, it’s crucial to make the right decision when
                            choosing the right kind of life insurance. Primerica is here to help families like yours.</p>

                        <div className='message_footer'>
                            <button className='btn view_thread_btn'>
                                View Thread
                            </button>

                            <div>
                                <button className='btn'>
                                    <i class="fa-regular fa-heart"></i>
                                </button>

                                <button className='btn'>
                                    <i class="fa-solid fa-share fa-flip-horizontal"></i>
                                </button>

                                <button className='btn'>
                                    <i class="fa fa-tag" aria-hidden="true"></i>
                                </button>

                                <button className='btn'>
                                    <i class="fa-regular fa-circle-check"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    )
}
